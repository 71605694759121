import type { FC, ComponentProps } from 'react'
import classNames from 'classnames'

import { Button, LinkButton } from '@web-solutions/core/ui-elements'

import classes from './style.module.scss'

interface ActionButtonsProps extends ComponentProps<'div'> {
  submitText: string,
  skipText: string,
  onSubmit: () => void,
  onSkip: () => void
  iconRight?: boolean
  flare?: boolean
}

export const ActionButtons: FC<ActionButtonsProps> = ({ submitText, skipText, className, iconRight, flare, onSubmit, onSkip, ...props }) =>
  <div className={classNames(classes.actionsWrapper, className)} {...props}>
    <Button
      iconRight={iconRight}
      flare={flare}
      type="button"
      titleClassName={classes.sumbitTitle}
      title={submitText}
      onClick={onSubmit}
    />
    <LinkButton
      className={classes.skip}
      onClick={onSkip}
    >
      {skipText}
    </LinkButton>
  </div>