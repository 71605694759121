import { useRemoteConfig } from '@web-solutions/core/hooks/use-remote-config';

import { ProgressWrapper } from '../../components/progress-wrapper';

import { useNavigateManage } from '../../hooks/use-navigate-next-screen';

import { useManageContext } from '../../hooks/use-manage-context';

const category = 'manage_progress'

export const ProgressPage: React.FC = () => {
  const { navigateNextScreen } = useNavigateManage();
  const { progressContent } = useManageContext();
  const { mngProgressPage } = useRemoteConfig();

  const fixedLink = mngProgressPage?.fixedLink;
  const fixedButton = mngProgressPage?.fixedButton;

  const handleSubmit = () => {
    navigateNextScreen();
  }

  return (
    <ProgressWrapper
      fixedLink={fixedLink}
      fixedButton={fixedButton}
      category={category}
      onSubmit={handleSubmit}
    >
      {progressContent}
    </ProgressWrapper>
  );
};