import { AppLinks } from '@web-solutions/module-attribution'

export const APP_NAME = 'Stylix';

export const PROJECT_SUPPORT_SUBJECT = '[Stylix WEB] ';

export const SUPPORT_EMAIL = process.env.REACT_APP_SUPPORT_EMAIL;

export const APP_LINKS: AppLinks = {
  ADJUST_LINK: 'https://stylix.go.link/p',
  ADJUST_TOKEN: '1cgyz58v',
};
