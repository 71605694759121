import { useCallback } from "react";

import Analytics from "@web-solutions/module-analytics";

import { EVENT_ACTION } from "@web-solutions/core/constants/general";

import { useShowGooglePay } from "@web-solutions/core/hooks/use-show-payment-method";

const ANALYTICS = 'google_pay'

export const useGooglePayProcessor = ({ handleSubmit }: { handleSubmit: (formData: any) => Promise<void> }) => {
  const showGooglePay = useShowGooglePay();

  const handleGooglePayClick = useCallback(() => {
    Analytics.trackEvent(ANALYTICS, EVENT_ACTION.CLICK);
  }, []);

  const handleGooglePaySubmit = useCallback((formData: any) => {
    Analytics.trackEvent(ANALYTICS, EVENT_ACTION.SUBMIT);

    return handleSubmit(formData);
  }, [
    handleSubmit,
  ]);

  return {
    showGooglePay,
    handleGooglePayClick,
    handleGooglePaySubmit
  }
}