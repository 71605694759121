import { useState, } from 'react';
import queryString from 'query-string';

import Analytics from "@web-solutions/module-analytics";
import Attribution, { LinkTarget } from '@web-solutions/module-attribution';

export const useReturnToApp = ({ category = '' }: { category?: string } = {}) => {
  const { idfm, platform } = queryString.parse(window.location.search) as Record<string, string>;

  const [link] = useState(() => Attribution.getLinkToApp(platform ? platform === 'web' ? LinkTarget.WEB : LinkTarget.DEFAULT : undefined));

  const handleReturnAppClick = () => {
    Analytics.trackEvent(category, 'return_to_app');

    window.location.href = link;
  };

  return {
    available: !!link && !!idfm,
    link,
    handleReturnAppClick
  }
}