import { T } from '@web-solutions/module-localization';

import { ProductDetails } from 'core/store/billing/selectors';

import { useGetTrialPeriodByMode } from 'core/payment/hooks';

import classes from './style.module.scss';

interface Props {
  product: ProductDetails,
  periodMode: 'day' | 'week'
}

const tKey = 'core.payment_popup.trial_info_block'

export const TrialInfoBlock: React.FC<Props> = ({ product, periodMode }) => {
  const trialPeriod = useGetTrialPeriodByMode({ product, periodMode })

  return (
    <div className={classes.info_block}>
      <span className={classes.text}>
        <T k={`${tKey}.text`} tOptions={{ trialPeriod, ...product }} />
      </span>
    </div>
  )
}