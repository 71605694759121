import React, { useEffect } from 'react';

import Analytics from '@web-solutions/module-analytics';

import { useManageContext } from '../../hooks/use-manage-context';
import { Header } from '../../components/header';

import { t } from '../../../localization';
import { useNavigateManage } from '../../hooks/use-navigate-next-screen';

import classes from './style.module.scss';

const tKey = 'manage.offer_free_period';

export const OfferFreePeriodPage: React.FC = () => {
  const { offerFreePeriodContent } = useManageContext();
  const { navigatePrevSceen } = useNavigateManage();
 
  useEffect(() => {
    Analytics.trackEvent('manage_offer_free_period', 'open');
  }, []);

  const handleBackClick = () => {
    navigatePrevSceen();
  }

  return (
    <div className={classes.wrap}>
      <Header titleClassName={classes.title} title={t(`${tKey}.title`)} onBackClick={handleBackClick} withBackButton />

      {offerFreePeriodContent}
    </div>
  );
};