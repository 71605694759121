import { useSelector } from 'react-redux';

import useCoreTimer from '@web-solutions/core/hooks/use-timer';
import { formatToStringForTimer } from '@web-solutions/core/utils/format-to-string-for-timer';

export const useTimer = () => {
  const discountEndDate = useSelector((state) => state.billing.discountEndDate);
  const specialOfferEndDate = useSelector((state) => state.billing.specialOfferEndDate)
  const time = specialOfferEndDate || discountEndDate;
  const { minutes, seconds } = useCoreTimer(time);
  const shown = minutes >= 0 && seconds >= 0;

  return {
    shown,
    minutes: formatToStringForTimer(minutes),
    seconds: formatToStringForTimer(seconds),
    text: `${formatToStringForTimer(minutes)}:${formatToStringForTimer(seconds)}`,
  };
};
