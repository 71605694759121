import { type FC, useEffect } from 'react'

import Analytics from '@web-solutions/module-analytics';

import { EVENT_ACTION } from '@web-solutions/core/constants/general';

import { Button } from '@web-solutions/core/ui-elements';

import { t } from '../../../localization';

import { Header } from '../../components/header';

import { useReturnToApp } from '../../../hooks';
import { useNavigateManage } from '../../hooks/use-navigate-next-screen';
import { useManageContext } from '../../hooks/use-manage-context';

import { baseTKey, ANALYTICS } from './contants'

import classes from './style.module.scss';

export const DontMiss: FC = () => {
  const { navigatePrevSceen, navigateNextScreen } = useNavigateManage();
  const { dontMissConfig } = useManageContext();

  const { handleReturnAppClick: handleGoToApp } = useReturnToApp({ category: ANALYTICS });

  useEffect(() => {
    Analytics.trackEvent(ANALYTICS, EVENT_ACTION.OPEN);
  }, []);

  const handleBack = () => {
    Analytics.trackEvent(ANALYTICS, EVENT_ACTION.BACK);

    navigatePrevSceen();
  }

  const handleCancel = () => {
    Analytics.trackEvent(ANALYTICS, 'continue');

    navigateNextScreen();
  }

  return (
    <div className={classes.dontMissWrapper}>
      <Header
        withBackButton
        title={t(`${baseTKey}.title`)}
        titleClassName={classes.title}
        onBackClick={handleBack}
      />
      {dontMissConfig?.content}
      <div className={classes.buttonsWrapper}>
        <Button
          titleClassName={classes.goToAppButtonTitle}
          title={t(`${baseTKey}.go_app_btn`)}
          onClick={handleGoToApp}
        />
        <Button
          className={classes.cancelButton}
          titleClassName={classes.cancelButtonTitle}
          title={t(`${baseTKey}.cancel_btn`)}
          onClick={handleCancel}
        />
      </div>
    </div>
  )
}
