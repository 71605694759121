import { useStripe } from '@stripe/react-stripe-js';
import { useRecurly } from '@recurly/react-recurly';

import { useSolidgate } from '../solidgate/use-solidgate';

import { getPaymentSystem } from '../utils/storage';
import { PaymentSystem } from '../constants';

const usePayment = () => {
  const paymentSystem = getPaymentSystem();

  return paymentSystem === PaymentSystem.RECURLY
    ? useRecurly()
    : paymentSystem === PaymentSystem.STRIPE
      ? useStripe()
      : paymentSystem === PaymentSystem.SOLIDGATE
        ? useSolidgate()
        : {};
};

export default usePayment;
