import _memoize from 'lodash/memoize';

export const checkPaymentMethod = _memoize(function checkPaymentMethod(existingPaymentMethodRequired: boolean): Promise<boolean> {
  try {
    return client()
      .then(client =>
        client.isReadyToPay({
          apiVersion: 2,
          apiVersionMinor: 0,
          allowedPaymentMethods: [
            {
              type: 'CARD',
              parameters: {
                allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
                allowedCardNetworks: [
                  'AMEX',
                  'DISCOVER',
                  'INTERAC',
                  'JCB',
                  'MASTERCARD',
                  'VISA',
                ],
              },
            },
          ],
          existingPaymentMethodRequired,
        }))
      .then((r) => {
        return existingPaymentMethodRequired ? r.result && r.paymentMethodPresent : r.result;
      })
      .catch(() => {
        return false;
      });
  } catch (ex) {
    console.warn(ex);
    return Promise.resolve(false);
  }
});

function client() {
  //@ts-ignore
  if (window?.google?.payments?.api?.PaymentsClient) {
    //@ts-ignore
    return Promise.resolve(new window.google.payments.api.PaymentsClient({ environment: 'PRODUCTION', }));
  }
  else {
    return new Promise((resolve => {
      setTimeout(() => resolve(client()), 100);
    }));
  }
}