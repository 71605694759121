import type { FC } from 'react';
import classNames from 'classnames';

import classes from './style.module.scss';

interface Props {
  text: string,
  status: 'current' | 'next' | 'prev',
}

export const SingleWordProgress: FC<Props> = ({ text, status }) => (
  <div className={classNames(classes.status, classes[status])}>
    <div>{text}</div>
  </div>
);
