import type { FC } from 'react';
import classNames from 'classnames';

import { ReactComponent as CheckIcon } from './icons/check.svg'

import classes from './style.module.scss';

interface Props {
  text: string,
  addGap: boolean,
  status: 'current' | 'next' | 'prev',
}

export const DefaultProgress: FC<Props> = ({ text, addGap, status }) => (
  <div
    className={classNames(classes.status,
      classes[status],
      { [classes.gap]: addGap }
    )}
  >
    <div className={classes.point}>
      <div className={classes.center} >
        {status === 'prev' && <CheckIcon />}
      </div>
    </div>
    <div className={classes.text}>{text}</div>
  </div>
);
