import type { FC, ComponentProps } from 'react';
import classNames from 'classnames'

import classes from './style.module.scss';

interface OneClickFootnoteProps extends ComponentProps<'p'> {
  text: string
}

export const OneClickFootnote: FC<OneClickFootnoteProps> = ({ text, className, ...props }) => (
  <p className={classNames(classes.text, className)} {...props}>{text}</p>
)