import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { Button, Title } from "@web-solutions/core/ui-elements"
import { PaymentSystem } from "@web-solutions/react-billing";
import { selectSubscription, selectPaymentSystem } from '@web-solutions/manage-subscription/src/manage/slice';
import { useRemoteConfig } from '@web-solutions/core/hooks/use-remote-config';

import { SubscriptionStatus } from "../../../types";
import { TM, t } from '../../../../localization';

import classes from '../style.module.scss';
import { useManageContext } from '../../../hooks/use-manage-context';

interface FooterProps {
  tKey: string;
  onReactivateClick: () => void;
  onChangePlanClick: () => void;
  onCancelSubscriptionClick: () => void;
  onReturnToAppClick: () => void;
}

export const Footer: React.FC<FooterProps> = ({
  tKey,
  onReactivateClick,
  onChangePlanClick,
  onCancelSubscriptionClick,
  onReturnToAppClick
}) => {

  const subscription = useSelector(selectSubscription);
  const paymentSystem = useSelector(selectPaymentSystem);
  const { mngSubMainPage } = useRemoteConfig();
  const { fastCancelMode } = useManageContext();

  return (
    <>
      {subscription
        ? (
          <>
            {subscription.status === SubscriptionStatus.CANCELED || subscription.status === SubscriptionStatus.EXPIRED ?
              <>
                {(paymentSystem === PaymentSystem.SOLIDGATE || paymentSystem === PaymentSystem.RECURLY) && (
                  <Button
                    onClick={onReactivateClick}
                    className={classNames(classes.btn, { [classes.no_features]: fastCancelMode })}
                    titleClassName={classes.btnTitle}
                    title={t(`${tKey}.reactivate_btn`)}
                  />
                )}
              </>
              :
              <>
                {(paymentSystem === PaymentSystem.SOLIDGATE || paymentSystem === PaymentSystem.RECURLY) && !fastCancelMode && (
                  <Button
                    onClick={onChangePlanClick}
                    className={classes.btn}
                    titleClassName={classes.btnTitle}
                    title={<TM k={mngSubMainPage?.changePlanButtonText} defaultKey={t(`${tKey}.change_plan_btn`)} />}
                  />
                )}
                <Button
                  className={classNames(classes.btn, {
                    [classes.no_features]: fastCancelMode,
                    [classes.secondary_button]: !fastCancelMode,
                  })}
                  titleClassName={classNames(classes.btnTitle, classes.secondary_button)}
                  title={t(`${tKey}.cancel_sub_btn`)}
                  onClick={onCancelSubscriptionClick}
                />
              </>
            }
          </>
        )
        : (
          <>
            <Title level={'h1'}>{t(`${tKey}.no_subscription`)}</Title>
            <Button
              onClick={onReturnToAppClick}
              className={classes.btn}
              title={t(`${tKey}.return_to_app`)}
            />
          </>
        )}
    </>
  )

}