import type { PropsWithChildren, ComponentProps } from 'react';
import classNames from 'classnames';

import classes from './style.module.scss';

type TitleLevel = 'h1' | 'h2' | 'h3' | 'h4'

type TitleOwnProps<L extends TitleLevel = TitleLevel> = {
  level?: L;
};

export type TitleProps<L extends TitleLevel = TitleLevel> = TitleOwnProps<L> &
  PropsWithChildren<Omit<ComponentProps<L>, keyof TitleOwnProps>>;

export const Title = <L extends TitleLevel = 'h3'>({ level, className, children, ...props }: TitleProps<L>) => {
  const TitleComponent = level || 'h3'

  return <TitleComponent className={classNames([classes[`title_${level || 'h3'}`], className])} {...props}>{children}</TitleComponent>;
};

export default Title;
