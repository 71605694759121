import _omit from 'lodash/omit';
import _isEmpty from 'lodash/isEmpty';
import { createAction } from 'redux-actions';
import { ThunkAction } from 'redux-thunk';

import RemoteConfig from '@web-solutions/module-remote-config';

import type { RemoteConfig as RemoteConfigType } from '../../constants/remote-config';

import { SET } from './types';

const REMOTE_CONFIG_CACHE_TIME = 60;

export const setRemoteConfig = createAction(SET, <T extends RemoteConfigType>(cfg: T): { cfg: T } => ({ cfg }));

let _validate: ((cfg: RemoteConfigType) => void) | undefined;

export const init = <T extends RemoteConfigType>(
  magnusToken: string, 
  initialState: any,
  { validate, storageKey }: { validate?: (cfg: T) => void; storageKey?: string } = {}
): ThunkAction<Promise<any>, any, unknown, any> => (dispatch, getState) => {
  const {
    remoteConfig
  } = getState();

  return RemoteConfig.fetch(magnusToken, initialState, { duration: REMOTE_CONFIG_CACHE_TIME, storageKey })
    .catch((err) => {
      console.log('[ERROR FETCH MUTATOR]', err.message);
      return _isEmpty(_omit(remoteConfig, '_persist')) ? initialState : remoteConfig;
    })
    .then((cfg) => {
      dispatch(setRemoteConfig(cfg));
      _validate = validate as typeof _validate;
      validate?.(cfg);
      return cfg;
    });
};

export const reInit = (): ThunkAction<Promise<void>, any, unknown, any> => (dispatch, getState) => {
  return RemoteConfig.activate()
    .then((cfg) => {
      dispatch(setRemoteConfig(cfg as RemoteConfigType));
      _validate?.(cfg as RemoteConfigType);
    })
    .catch((err) => {
      console.log('[ERROR REINIT MUTATOR]', err.message);
    });
};




