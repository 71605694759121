import React, { Suspense } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import { ROUTES } from './routes';

import { GlobalWrapper } from './components/global-wrapper'

import { TerminateMainScreen } from './main';
import { TerminateEmailSentScreen } from './email-sent';
import { TerminateConfirmScreen } from './confirm';
import { TerminateErrorScreen } from './error';
import { TerminateSuccessScreen } from './success';
import { TerminateContextProvider } from './hooks/use-terminate-context';

interface TerminateProps {
  logo?: React.ReactNode;
  terminateSuccessContent?: React.ReactNode;
}

const SCREENS = {
  [ROUTES.MAIN]: <TerminateMainScreen />,
  [ROUTES.EMAIL_SENT]: <TerminateEmailSentScreen />,
  [ROUTES.CONFIRM]: <TerminateConfirmScreen />,
  [ROUTES.ERROR]: <TerminateErrorScreen />,
  [ROUTES.SUCCESS]: <TerminateSuccessScreen />,
};

export const Terminate: React.FC<TerminateProps> = ({ logo, terminateSuccessContent }) => {

  const context = {
    logo,
    terminateSuccessContent,
  }

  return (
    <Suspense fallback={<div />}>
      <TerminateContextProvider value={context}>
        <BrowserRouter>
          <GlobalWrapper>
            <Routes>
              {Object.values(ROUTES).map((r, i) => (
                <Route key={i} path={r} element={SCREENS[r]} />
              ))}
              <Route path='*' element={<Navigate to={ROUTES.MAIN} />} />
            </Routes>
          </GlobalWrapper>
        </BrowserRouter>
      </TerminateContextProvider>
    </Suspense>
  )
};
