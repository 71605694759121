import { useMemo } from 'react'

export type ProgressStep = 'order' | 'offer' | 'guide' | 'create_account'

export const useGetActualSteps = (afterPurchaseFlow: any): ProgressStep[] => {
  const actualSteps = useMemo(() => {

    const additionalSteps = Array.from(
      //@ts-ignore
      afterPurchaseFlow.reduce((acc, value) => {
        if (value === 'CREATE_ACCOUNT') {
          acc.add('account')
        } else {
          acc.add('offer')
        }

        return acc
      }, new Set<ProgressStep>())
    )

    return ['order', ...additionalSteps, 'guide'] as ProgressStep[]
  }, [afterPurchaseFlow])

  return actualSteps
}