import { useState, useEffect, } from 'react';

import { checkPaymentMethod } from '../utils/google-pay';

export const useGooglePay = (existingPaymentMethodRequired: boolean) => {
  const [result, setResult] = useState<boolean | null>(null);

  useEffect(() => {
    setResult(null);
    checkPaymentMethod(existingPaymentMethodRequired)
      .then((r: boolean) => {
        setResult(r);
      })
  }, [existingPaymentMethodRequired]);

  return result;
};
