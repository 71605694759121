import React from 'react';
import classNames from 'classnames';

import { tm, TM } from '@web-solutions/module-localization';

import { ProductDetails } from '@web-solutions/core/store/billing/selectors';
import { getPriceTitle } from '@web-solutions/core/utils/products';

import RoundCheckbox from './components/round-checkbox';

import classes from './style.module.scss';

interface ItemProps {
  isActive: boolean,
  product: ProductDetails,
  onClick: (product: ProductDetails) => void,
  titleText: string,
  subTitleText?: string,
  perText: string,
  perTitle: string,
};

export const Ticket: React.FC<ItemProps> = ({
  isActive,
  product,
  onClick,
  titleText,
  subTitleText,
  perText,
  perTitle,
}) => {
  const {
    textLineThrough,
    accentTextLineThrough,
    badgeTitle,
  } = product;

  const { currencySymbol, integerPart, fractionalPart, currencySuffix } = getPriceTitle(perTitle);

  const handleItemClick = (e: React.SyntheticEvent) => {
    e.preventDefault();
    onClick(product);
  };

  const handleCheckClick = () => {
    onClick(product);
  }

  return (
    <div className={classes.wrap}>
      <button
        type="button"
        onClick={handleItemClick}
        className={classNames(classes.container,
          {
            [classes.isActiveContainer]: isActive,
            [classes.big]: badgeTitle,
          })}
      >
        {badgeTitle && <div className={classes.discount}>{tm(badgeTitle, '', { ...product })}</div>}
        <div className={classes.leftPart}>
          <RoundCheckbox isActive={isActive} onCheckClick={handleCheckClick} />
          <div className={classes.detailWrap}>
            <p className={classes.productTitle}>
              {titleText}
            </p>
            {(textLineThrough || subTitleText) &&
              <p className={classes.productSubTitle}>
                {textLineThrough && <span className={classes.lineThrough}>{tm(textLineThrough, '', product)}</span>}
                &nbsp;
                {subTitleText && <TM k={subTitleText} defaultKey='' tOptions={product} />}
              </p>
            }
            {accentTextLineThrough &&
              <span className={classes.accentTextLineThrough}>
                {tm(accentTextLineThrough, '', product)}
              </span>
            }
          </div>
        </div>

        <div className={classes.accentWrap}>
          <div className={classes.accentTitle}>
            <span className={classes.currencySymbol}>{currencySymbol}</span>
            <span className={classes.integerPart}>{integerPart}</span>
            <span className={classes.fractionalPart}>{fractionalPart}</span>
            {currencySuffix && <span className={classes.currencySuffix}>{currencySuffix}</span>}
          </div>

          <p className={classes.accentSubTitle}>
            {perText}
          </p>
        </div>
      </button>
    </div>
  );
};