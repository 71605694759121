import { initializeApp as initFirebase } from 'firebase/app';

import { parseUrlParams } from '@web-solutions/core/utils/url-sync';

import { render } from './render';

parseUrlParams();

let pType = window.location.pathname.split('/')[1];

let promiseApp;

switch (pType) {
  case 'terminate':
    promiseApp = import('./screens/terminate').then(r => r.default);
    break;
  case 'manage':
    promiseApp = import('./screens/manage').then(r => r.default);
    break;
  case 'quiz-book':
    promiseApp = import('./screens/quiz/book').then(r => r.default);
    break;
  default:
    promiseApp = import('./screens/quiz').then(r => r.default);
    break;
}

initFirebase({
  apiKey: 'AIzaSyBbozoLu5b_YmF6cT-3NBf1D3OdvvOd4Ow',
  authDomain: 'stylix-6ed89.firebaseapp.com',
  projectId: 'stylix-6ed89',
  storageBucket: 'stylix-6ed89.firebasestorage.app',
  messagingSenderId: '864219731297',
  appId: '1:864219731297:web:351daf603cb7f666376120',
  measurementId: 'G-WJ2R09RT6H',
});

promiseApp.then(render);
