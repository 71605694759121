import { type FC, useState, useLayoutEffect } from 'react'
import { useSelector } from 'react-redux'

import EmailEditor from '@web-solutions/core/payment/components/edit-email-field'

import classes from '../../style.module.scss'

export const OneTimeEmailEditor: FC = () => {
  const [isVisible, setIsVisible] = useState(false);

  const email = useSelector((state: any) => state.profile.email);

  useLayoutEffect(() => {
    if (!email) {
      setIsVisible(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {isVisible && (
        <>
          <div className={classes.divider} />
          <EmailEditor isSimpleView={false} isInitOrdersAvailable={false} />
        </>
      )}
    </>
  )
}
