import React, { useEffect } from 'react';

import Analytics from '@web-solutions/module-analytics';

import { EVENT_ACTION } from '@web-solutions/core/constants/general';

import { Title, Button, Text } from '@web-solutions/core/ui-elements';

import { useRemoteConfig } from '@web-solutions/core/hooks/use-remote-config';

import { goAppLink, useSliceDispatch } from '../../../slice';

import { t, T } from '../../../../localization';

import { ReactComponent as IconClose } from '../../../components/icons/close-icon.svg';

import { useManageContext } from '../../../hooks/use-manage-context';

import classes from './style.module.scss';

const tKey = 'manage.cancel_success';

export const WithoutLogo: React.FC = () => {
  const { mngSubCancelSuccessPage } = useRemoteConfig();
  const { cancelSuccessContent } = useManageContext();
  const dispatch = useSliceDispatch();

  useEffect(() => {
    Analytics.trackEvent('manage_cancel_success', EVENT_ACTION.OPEN);
  }, []);

  const handleCloseClick = () => {
    Analytics.trackEvent('manage_cancel_success', EVENT_ACTION.CLOSE);
    dispatch(goAppLink());
  };

  return (
    <div className={classes.wrap}>
      <div>
        <Title level="h1" className={classes.title}>{t(`${tKey}.title`)}</Title>
        <Text className={classes.text}><T k={`${tKey}.subtitle`} /></Text>
      </div>
      {mngSubCancelSuccessPage.closeEnabled && <button type="button" onClick={handleCloseClick} className={classes.close}>
        <IconClose width="16px" height="16px" />
      </button>}
      {cancelSuccessContent}
      <Text className={classes.text}>{t(`${tKey}.text`)}</Text>
      <Button
        onClick={handleCloseClick}
        type="button"
        className={classes.bottom_button}
        title={t(`${tKey}.button_title`)}
      />
    </div>
  );
};
