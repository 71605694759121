import {
  getAuth,
  OAuthProvider,
  EmailAuthProvider,
  linkWithCredential,
  linkWithRedirect,
  signInWithCustomToken,
} from "firebase/auth";

import DeviceProps from '@magnus/react-native-device-props';

// @ts-ignore
import { request as requestBillingApi } from '@web-solutions/module-billing/api/network';

import request from '@web-solutions/core/utils/network';

const billingApi = requestBillingApi(process.env.REACT_APP_API_BILLING_URL);

const authDeviceId = (idfm: string) =>
  billingApi.post('/auth/device-id', { device_id: idfm, });

export const auth = () =>
  DeviceProps.getIDFM()
    .then(idfm => authDeviceId(idfm))

const authFirebase = (idfm: string) =>
  request.post<any, { custom_token: string }>('/auth/firebase/create-custom-token', { uid: idfm, })
    .then(({ custom_token }) => signInWithCustomToken(getAuth(), custom_token))
    .then((userCred) => userCred.user.getIdToken())
    .then((token) => request.post('/auth/firebase', { token, }));

export function signInWithApple(): Promise<void> {
  const provider = new OAuthProvider('apple.com');
  return linkWithRedirect(getAuth().currentUser!, provider)
}

async function linkWithEmailAndPassword(currentUser: any, email: string, password: string): Promise<boolean> {
  const credential = EmailAuthProvider.credential(email, password);
  await linkWithCredential(currentUser, credential);
  return true;
}

export async function signUpWithEmail(email: string, password: string): Promise<boolean> { 
  try {
    return await linkWithEmailAndPassword(getAuth().currentUser, email, password);
  } catch (error) {
    const idfm = await DeviceProps.getIDFM();
    await authFirebase(idfm);
    return await linkWithEmailAndPassword(getAuth().currentUser, email, password);
  }
}
