export const SUBSCRIPTION_NAMES = {
  MAIN: 'MAIN',
  SUCCESS: 'SUCCESS',
  STYLE_GUIDE: 'STYLE_GUIDE',
  WARDROBE_GUIDE: 'WARDROBE_GUIDE',
} as const;

type AfterPurchase = Exclude<keyof typeof SUBSCRIPTION_NAMES, "MAIN" | "SUCCESS">
export type AfterPurchaseRoutes = AfterPurchase[];

export const QUIZ_NAMES = {}