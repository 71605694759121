import type { ComponentProps, FC } from 'react';
import classNames from 'classnames';

import classes from './style.module.scss';

interface CheckboxPropsBase {
  isChecked: boolean;
  classNameCheckbox?: string;
}

export type CheckboxProps = Omit<ComponentProps<'input'>, keyof CheckboxPropsBase> & CheckboxPropsBase

const Checkbox: FC<CheckboxProps> = ({ defaultChecked, isChecked, onChange, classNameCheckbox, ...props }) => (
  <div className={classes.checkbox}>
    <input type="checkbox" id="checkbox" defaultChecked={defaultChecked} checked={isChecked} onChange={onChange} {...props} />
    <span className={classNames(classes.checkbox__mark, classNameCheckbox)} />
  </div>
);

export default Checkbox;
