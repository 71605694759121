export enum QuizStepKind {
  Square = 'square',
  Rectangle = 'rectangle',
  Mini = 'mini',
  Bipolar = 'bipolar',
  Numeric = 'numeric',
  Rate = 'rate',
  Oval = 'oval',
}

export enum QuizStepView {
  BG = 'bg',
  CAPTION = 'caption',
}
