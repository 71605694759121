import React from 'react';
import classNames from 'classnames';

import { T } from '@web-solutions/module-localization';

import classes from './style.module.scss';

interface Props {
  className: string;
}

const TrialReminder: React.FC<Props> = ({ className }) => (
  <div className={classNames(classes.reminder, className)}>
    <T k={'core.trial_reminder_text'} />
  </div>
);

export default TrialReminder;