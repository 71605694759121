import React from 'react';
import classNames from 'classnames';

import { default as Modal } from '../modal';

import { ReactComponent as IconClose } from './close.svg';

import classes from './style.module.scss';

interface Props {
  isOpen: boolean;
  children: React.ReactNode;
  className?: string;
  onCloseClick?: () => void;
}

export const ModalDialog: React.FC<Props> = ({
  isOpen,
  children,
  className,
  onCloseClick,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      className={classNames(classes.popup, className)}
      overlayClassName={classes.overlay}
    >
      {onCloseClick &&
        <div className={classes.close} onClick={onCloseClick}>
          <IconClose />
        </div>
      }
      {children}
    </Modal>
  )
}
