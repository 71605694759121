import { SET } from './types';

import * as TYPES from './types';

const initialState = {};

const remoteConfigReducer = (state = initialState, action: TYPES.RemouteConfigActionTypes) => {
  switch (action.type) {
    case SET:
      return {
        ...state,
        ...action.payload.cfg,
      };
    default:
      return state;
  }
};

export default remoteConfigReducer;

