import { useSelector } from 'react-redux';

import { selectTrialPrice } from '@web-solutions/core/store/billing/selectors';

import { PlanType } from '@web-solutions/core/constants/remote-config';

import { useRemoteConfig } from '@web-solutions/core/hooks/use-remote-config';

export const useIsSingleTrialPlanMode = () => {
  const { planType } = useRemoteConfig();

  const trialPrice = useSelector(selectTrialPrice);

  return {
    planType,
    trialPrice,
    isAvailable: !!trialPrice && planType === PlanType.TRIAL
  }
}