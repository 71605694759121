import type { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button } from '@web-solutions/core/ui-elements';

import { t } from '../../localization';

import { ROUTES } from '../routes';

import { TerminateTemplate } from '../components/template';

import { useTerminateContext } from '../hooks/use-terminate-context';

const tKey = 'terminate.error';

export const TerminateErrorScreen: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { logo } = useTerminateContext();

  const code = location.state?.code;
  const isCodeMessage = t(`${tKey}.messages`, { returnObjects: true })?.[code];
  const codeMessage = t(`${tKey}.messages.${isCodeMessage ? code : '1'}`);
  const errorMessage = code ? codeMessage : t(`${tKey}.description`);

  const handleButtonClick = (e: Event) => {
    e.preventDefault();

    navigate(ROUTES.MAIN);
  };

  return (
    <TerminateTemplate
      title={t(`${tKey}.title`)}
      text={errorMessage}
      logo={logo}
    >
      <Button
        title={t(`${tKey}.button_title`)}
        onClick={handleButtonClick}
      />
    </TerminateTemplate>
  );
};
