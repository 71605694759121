import type { Theme } from "@web-solutions/core/interfaces/theme"

export const applyTheme = ({ mode, colors }: Theme) => {
  const root = document.querySelector(':root') as HTMLElement;

  if (root) {
    if (mode) {
      root.setAttribute('data-theme', mode)
    }

    if (colors) {
      Object.entries(colors).forEach(([key, value]) => {
        root.style.setProperty(`--${key}`, value);
      })
    }
  }
}