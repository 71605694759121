import type { FC } from 'react'

import { TM } from '@web-solutions/module-localization';

import type { ProductDetails } from '@web-solutions/core/store/billing/selectors';

import { Title, Text, Image } from '@web-solutions/core/ui-elements';

import { useRemoteConfig } from '@web-solutions/core/hooks/use-remote-config';

import { IMAGES } from './images'

import classes from './style.module.scss'

interface TrialPlanProps {
  product: ProductDetails,
  onClick: (product: ProductDetails) => void,
};

export const TrialPlan: FC<TrialPlanProps> = ({ product, onClick }) => {
  const {
    openPaymentModalOnProductClick,
  } = useRemoteConfig();

  const { title, subTitle, accentTitle } = product

  const handleItemClick = (e: React.SyntheticEvent) => {
    e.preventDefault();
    onClick(product);
  };

  return (
    <button
      type='button'
      disabled={!openPaymentModalOnProductClick}
      className={classes.wrapper}
      onClick={handleItemClick}
    >
      <div className={classes.content}>
        <Image img={IMAGES.HAND} alt='hand' />
        <div>
          <Title className={classes.title}>
            <TM k={title} tOptions={product} />
          </Title>
          <Text className={classes.subtitle}>
            <TM k={subTitle} tOptions={product} />
          </Text>
        </div>
      </div>
      <span className={classes.price}>
        <TM k={accentTitle} tOptions={product} />
      </span>
    </button>
  )
}
