import classNames from 'classnames';

import classes from '../../style.module.scss';

interface GooglePayContainerProps {
  isButtonVisible: boolean;
  options: any;
}

export const GooglePayContainer: React.FC<GooglePayContainerProps> = ({
  isButtonVisible,
  options,
}) => (
  <div className={classNames(classes.buttonWrapper, { [classes.hidden]: !isButtonVisible })}>
    <div id={options?.googlePayButtonParams?.containerId}></div>
  </div>
)