import { useCallback, useState } from "react";

import Analytics from "@web-solutions/module-analytics";

import { EVENT_ACTION } from "@web-solutions/core/constants/general";

const ANALYTICS = 'error_popup'

interface Props {
  onSubmit?: () => void,
  onCancel?: () => void
  onClose?: () => void
}

export const useErrorPopupProcessor = ({ onSubmit, onCancel, onClose }: Props) => {
  const [errorCode, setErrorCode] = useState<string>('');
  const [isOpenErrorPopup, setIsOpenErrorPopup] = useState(false);

  const handleErrorPopupCancel = useCallback((isPaypal?: boolean) => {
    Analytics.trackEvent(ANALYTICS, EVENT_ACTION.CANCEL, isPaypal ? { mode: 'paypal' } : undefined);

    setIsOpenErrorPopup(false);

    if (isPaypal) {
      onCancel && onCancel();
    } else {
      onClose && onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onClose]);

  const handleErrorPopupSubmit = useCallback(() => {
    Analytics.trackEvent(ANALYTICS, EVENT_ACTION.SUBMIT);

    setIsOpenErrorPopup(false);

    onSubmit && onSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    errorCode,
    isOpenErrorPopup,
    setErrorCode,
    setIsOpenErrorPopup,
    handleErrorPopupSubmit,
    handleErrorPopupCancel
  }
}