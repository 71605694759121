import type { FC, PropsWithChildren } from 'react'

import { useScrollOnLocationChange } from '@web-solutions/core/hooks/use-scroll-on-location-change';

import classes from './style.module.scss'

export const GlobalWrapper: FC<PropsWithChildren> = ({ children }) => {
  useScrollOnLocationChange({ mode: 'root' });

  return (
    <div className={classes.terminateGlobalWrapper}>
      {children}
    </div>
  )
}
