import _find from 'lodash/find';
import _round from 'lodash/round';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

import { createVATPrice, createVATComboPrice } from '@web-solutions/core/utils/vat';
//@ts-ignore
import { COUNTRIES } from '@web-solutions/module-billing/utils';

import { Period, Product, ProductInfo, CURRENCY_SYMBOL_BY_CODE } from '../constants';

dayjs.extend(duration)

export const getPreparedPrice = (price: number) => {
  const roundedPrice = _round(price, 2);

  return Number.isInteger(roundedPrice) ? Math.trunc(roundedPrice) : roundedPrice
}

export const getPreparedWeekPrice = (period: Period, amount: number): number => {
  let result = amount;

  switch (period) {
    case 'P2W':
      result = amount / 2;
      break;
    case 'P3W':
      result = amount / 3;
      break;
    case 'P1M':
      result = amount / 4;
      break;
    case 'P2M':
      result = amount / 8;
      break;
    case 'P3M':
      result = amount / 13;
      break;
    case 'P6M':
      result = amount / 26;
      break;
    case 'P1Y':
      result = amount / 52;
      break;
    default:
      result = Number(amount);
  }

  return getPreparedPrice(result);
};

export const getPreparedDayPrice = (period: Period, amount: number): number => {
  let result = amount;

  switch (period) {
    case 'P1W':
      result = amount / 7;
      break;
    case 'P2W':
      result = amount / 7 / 2;
      break;
    case 'P3W':
      result = amount / 7 / 3;
      break;
    case 'P1M':
      result = amount / 30;
      break;
    case 'P2M':
      result = amount / 30 / 2;
      break;
    case 'P3M':
      result = amount / 30 / 3;
      break;
    case 'P6M':
      result = amount / 30 / 6;
      break;
    case 'P1Y':
      result = amount / 365;
      break;
    default:
      result = Number(amount);
  }

  return getPreparedPrice(result);
};

export const getPreparedPeriod = (period: string): Period => {
  switch (period) {
    case 'P7D':
      return 'P1W';
    case 'P14D':
      return 'P2W';
    case 'P30D':
      return 'P1M';
    case 'P60D':
      return 'P2M';
    case 'P90D':
      return 'P3M';
    case 'P180D':
      return 'P6M';
    default:
      return period as Period;
  }
};

const getPeriodInWeeks = (period: string): number => {
  const weeks = dayjs.duration(period).asWeeks();
  return weeks >= 1 ? Math.floor(weeks) : +(weeks).toFixed(1);
}

export const getCurrencySymbols = (currency: string) => {
  const currencySymbol: string = CURRENCY_SYMBOL_BY_CODE[currency?.toLowerCase?.() as keyof typeof CURRENCY_SYMBOL_BY_CODE] ?? currency;
  let currencySuffix = '';

  if (currency === 'AUD' || currency === "CAD") {
    currencySuffix = ` ${currency}`;
  }

  return { currencySymbol, currencySuffix }
}

function getPrevPrice(amount: number, discount: number) {
  return getPreparedPrice(_round(+amount * 100 / (100 - discount), 1) - 0.01);
}

type MappingPricesToProductsParams = {
  products: any[],
  prices: Product[],
  countryCode: string,
  displayVAT?: boolean
}

const mappingPricesToProducts = ({ products, prices, countryCode, displayVAT }: MappingPricesToProductsParams): ProductInfo[] => {
  const country = COUNTRIES[countryCode as keyof typeof COUNTRIES];

  const defaultProduct = _find(products, { default: true }) || products[0];
  const defaultPrice = _find(prices, { id: defaultProduct?.id });
  const { currency: defaultCurrency } = _find(defaultPrice?.currencies, i => i.country === country || i.country.includes(country)) || defaultPrice || {};

  return products.reduce((pds, item) => {
    const price = prices.find(({ id }) => id === item.id) as ProductInfo;
    if (price) {
      const { discount, trialDiscount, } = item;
      const { trial_period_days } = price;
      const { amount, currency, trial_price_amount } = _find(price.currencies, i => (i.country === country || i.country.includes(country)) && i.currency === defaultCurrency) || price;

      const { currencySymbol, currencySuffix } = getCurrencySymbols(currency);

      const period = getPreparedPeriod(price.period || item.period);

      price.trialDays = +trial_period_days;
      price.trialWeeks = getPeriodInWeeks(`P${trial_period_days}D`);
      price.periodWeeks = getPeriodInWeeks(period);
      price.trialPriceAmount = getPreparedPrice(+trial_price_amount);
      price.trialPrice = `${currencySymbol}${getPreparedPrice(+trial_price_amount)}${currencySuffix}`;
      price.trialDayPrice = `${currencySymbol}${getPreparedPrice(+trial_price_amount / +trial_period_days)}${currencySuffix}`;
      price.prevTrialPriceAmount = getPrevPrice(price.trialPriceAmount, trialDiscount);
      price.prevTrialPrice = `${currencySymbol}${price.prevTrialPriceAmount}${currencySuffix}`;
      price.savedTrialPrice = `${currencySymbol}${getPreparedPrice(price.prevTrialPriceAmount - price.trialPriceAmount)}${currencySuffix}`;

      price.isTrial = price.trialDays > 0;
      price.isIntroductory = price.trialPriceAmount > 0;

      price.price = price.priceText = `${currencySymbol}${getPreparedPrice(+amount)}${currencySuffix}`;
      price.priceAmount = getPreparedPrice(+amount);
      price.prevPriceAmount = getPrevPrice(+amount, discount);
      price.prevPrice = `${currencySymbol}${price.prevPriceAmount}${currencySuffix}`;
      price.savedPrice = `${currencySymbol}${getPreparedPrice(price.prevPriceAmount - +amount)}${currencySuffix}`;

      price.weekPriceAmount = getPreparedWeekPrice(period, +amount);
      price.weekPrice = `${currencySymbol}${price.weekPriceAmount}${currencySuffix}`;
      price.prevWeekPriceAmount = getPrevPrice(+price.weekPriceAmount, discount);
      price.prevWeekPrice = `${currencySymbol}${price.prevWeekPriceAmount}${currencySuffix}`;
      price.savedWeekPrice = `${currencySymbol}${getPreparedPrice(price.prevWeekPriceAmount - price.weekPriceAmount)}${currencySuffix}`;

      price.dayPriceAmount = getPreparedDayPrice(period, +amount);
      price.dayPrice = `${currencySymbol}${price.dayPriceAmount}${currencySuffix}`;
      price.prevDayPriceAmount = getPrevPrice(+price.dayPriceAmount, discount);
      price.prevDayPrice = `${currencySymbol}${price.prevDayPriceAmount}${currencySuffix}`;
      price.savedDayPrice = `${currencySymbol}${getPreparedPrice(price.prevDayPriceAmount - price.dayPriceAmount)}${currencySuffix}`;

      const baseCreateVATPriceParams = {
        currency,
        countryCode,
        displayVAT
      }

      const { vat, price: vatPrice } = createVATPrice({ amount, ...baseCreateVATPriceParams });
      const { vat: dayVat, price: vatDayPrice } = createVATPrice({ amount: price.dayPriceAmount, ...baseCreateVATPriceParams });
      const { vat: weekVat, price: vatWeekPrice } = createVATPrice({ amount: price.weekPriceAmount, ...baseCreateVATPriceParams });
      const { vat: vatTrial, price: vatTrialPrice } = createVATPrice({ amount: trial_price_amount, ...baseCreateVATPriceParams });

      price.vat = vat;
      price.vatPrice = vatPrice;
      price.vatPriceCombo = createVATComboPrice({ vat, vatPrice, price: price.priceText, displayVAT });

      price.weekVat = weekVat;
      price.vatWeekPrice = vatWeekPrice;
      price.vatWeekPriceCombo = createVATComboPrice({ vat: weekVat, vatPrice: vatWeekPrice, price: price.weekPrice, displayVAT });

      price.dayVat = dayVat;
      price.vatDayPrice = vatDayPrice;
      price.vatDayPriceCombo = createVATComboPrice({ vat: dayVat, vatPrice: vatDayPrice, price: price.dayPrice, displayVAT });

      price.vatTrial = vatTrial;
      price.vatTrialPrice = vatTrialPrice;
      price.vatTrialPriceCombo = createVATComboPrice({ vat: vatTrial, vatPrice: vatTrialPrice, price: price.trialPrice, displayVAT });

      price.firstPaymentAmount = +(price.isTrial ? price.trialPriceAmount : price.priceAmount)

      //@ts-ignore
      const expireAt = dayjs().add(dayjs.duration(period)).add(dayjs.duration(price.trialDays, 'd'));
      //@ts-ignore
      const introExpireAt = dayjs().add(dayjs.duration(price.trialDays, 'd'));

      pds.push({
        ...item,
        ...price,
        amount,
        period,
        currency,
        expireDate: expireAt.format('LL'),
        introExpireDate: introExpireAt.format('LL'),
        expireAt: dayjs(expireAt).unix(),
        isOneTimePurchase: period === 'ONETIME',
      });
    }

    return pds;
  }, []);
};

export default mappingPricesToProducts;
