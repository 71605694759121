import type { FC } from 'react';

import { Button } from '@web-solutions/core/ui-elements';
import Analytics from '@web-solutions/module-analytics';

//@ts-ignore
import { SUPPORT_EMAIL } from 'src/constants/general';

import { t } from '../../../localization';

import { useReturnToApp } from '../../../hooks/use-return-to-app';

import classes from './style.module.scss';

interface Props {
  subject?: string
}

export const TerminateFooter: FC<Props> = ({ subject = 'Terminate Manage' }) => {
  const { available, handleReturnAppClick } = useReturnToApp({ category: 'terminate' });

  const handleSupportClick = () => {
    Analytics.trackEvent('terminate', 'contact_us');

    return true
  }

  return (
    <footer className={classes.footer}>
      {available && <Button className={classes.btn} titleClassName={classes.btn_title} title={t('terminate.btn')} onClick={handleReturnAppClick} />}
      <a
        href={`mailto:${SUPPORT_EMAIL}?subject=${encodeURIComponent(subject)}`}
        className={classes.bottom_button}
        onClick={handleSupportClick}
      >
        {t(`terminate.footer.bottom_button_title`)}
      </a>
    </footer>
  )
};
