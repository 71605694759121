import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Analytics from "@web-solutions/module-analytics";

import { checkRedirectResults } from "@web-solutions/core/store/billing/actions";

import { selectLoading } from "@web-solutions/core/store/billing/selectors";

import { EVENT_ACTION } from "@web-solutions/core/constants/general";

import type { Subscription } from "@web-solutions/core/interfaces/billing";

const ANALYTICS = 'modal_payment'

interface Props {
  handlePaymentSuccess: (purchase: Subscription) => Promise<void>,
}

export const useCheckRedirectResults = ({ handlePaymentSuccess }: Props) => {
  const dispatch = useDispatch<any>();

  const isLoadingBilling = useSelector(selectLoading);

  useEffect(() => {
    if (!isLoadingBilling) {
      dispatch(checkRedirectResults())
        .then(
          (p: Subscription | null) => {
            if (p) {
              handlePaymentSuccess(p);
            }
          },
          (error: any) => {
            Analytics.trackEvent(ANALYTICS, EVENT_ACTION.ERROR, { message: error?.message, paymentSystem: error?.paymentSystem });
          }
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingBilling]);
}