import { useEffect, useState } from 'react';
import queryString from 'query-string';

import { useReturnToApp } from '../../../../hooks';

export const useGetUrl = () => {
  const { link: appLink } = useReturnToApp();

  const [url, setUrl] = useState(() => appLink);

  const { platform } = queryString.parse(window.location.search);

  useEffect(() => {
    let newUrl = appLink;

    if (platform) {
      const parsedUrl = queryString.parseUrl(appLink);

      if (platform === 'web') {
        newUrl = queryString.stringifyUrl({
          url: `${parsedUrl.url}settings`,
          query: parsedUrl.query,
        });
      } else {
        parsedUrl.query.pathname = 'settings';

        newUrl = queryString.stringifyUrl(parsedUrl);
      }
    }

    setUrl(newUrl);
  }, [appLink, platform]);

  return url;
};
