export const getContentByCode = (code: string | number | undefined) => {
  //@ts-ignore
  const s = code?.toString()?.replaceAll('.', '');

  if (s === '001' || s === '002' || s === '101' || s === '213' ||
    s === '301' || s === '310' || s === '401' || s === '402' ||
    s === '404' || s === '405' || s === '406' || s === '407' ||
    s === '409' || s === '501' || s === '602') {
    return 'cardIssuerWithImage'
  }

  if (s === '203' || s === '205' || s === '207' || s === '210' ||
    s === '215' || s === '504' || s === '505' || s === '507' ||
    s === '509' || s === '510' || s === '511' || s === '702' ||
    s === '703' || s === '704' || s === '705' || s === '706') {
    return 'cardIssuerEmailFooter'
  }

  if (s === '003' || s === '306' || s === '403') {
    return 'cardIssuer'
  }

  if (s === '201' || s === '208' || s === '209') {
    return 'invalidData'
  }

  if (s === '211' || s === '212' || s === '309' || s === '503' ||
    s === '508' || s === '507' || s === '502') {
    return 'cardIssuerInvalidFlow'
  }
  if (s === '214') {
    return 'subscriptionLocked'
  }
  if (s === '302') {
    return 'insufficientFunds'
  }
  if (s === '303') {
    return 'paymentAmountLimit'
  }
  if (s === '304' || s === '305' || s === '308' || s === '408' || s === '601') {
    return 'cardIssuerLaw'
  }
  if (s === '307') {
    return 'cardBrandNotSupported'
  }
  if (s === '701') {
    return 'tokenNotFound'
  }

  return 'cardIssuerEmailFooter'
}