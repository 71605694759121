import { Subscription } from "core/interfaces/billing";
import { PayPalBtn } from "core/payment/components/paypal";
import { ProductDetails } from "core/store/billing/selectors";

import classes from '../../style.module.scss'

interface PayPalContainerProps {
  activeProduct: ProductDetails;
  isButtonVisible: boolean;
  onPayPalSubmit: (formData: any) => Promise<void>;
  onPayPalSuccess: (p: Subscription) => void;
  onPayPalError: (e: any) => void;
}

export const PayPalContainer: React.FC<PayPalContainerProps> = ({
  activeProduct,
  isButtonVisible,
  onPayPalSubmit,
  onPayPalSuccess,
  onPayPalError,
}) => {
  if (!isButtonVisible) {
    return null;
  }

  return <PayPalBtn
    product={activeProduct}
    className={classes.paypal}
    onSubmit={onPayPalSubmit}
    onSuccess={onPayPalSuccess}
    onError={onPayPalError}
  />
}