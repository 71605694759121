import { useRef, useEffect, type CSSProperties } from 'react';
import _debounce from 'lodash/debounce';
import UAParser from 'ua-parser-js';

const r = new UAParser().getResult();

export const useUpdateWrapperSize = <T extends HTMLElement = HTMLElement>(initialSizes: Pick<CSSProperties, 'maxHeight' | 'minHeight'>) => {
  const refElement = useRef<T>(null);

  const refInnerHeight = useRef(window.innerHeight);
  const refElementInitialSizes = useRef(initialSizes)

  useEffect(() => {
    if (r?.os?.name === 'iOS') {
      const handleResize = _debounce(() => {
        if (refElement.current) {
          const updatingValue = Math.round(window.visualViewport?.height ?? 0) < refInnerHeight.current ? `${refInnerHeight.current - Math.round(window.visualViewport?.height ?? 0)}px` : 'none'

          if (updatingValue === 'none') {
            refElement.current.style.maxHeight = refElementInitialSizes.current.maxHeight as string
            refElement.current.style.minHeight = refElementInitialSizes.current.minHeight as string
          } else {
            refElement.current.style.maxHeight = updatingValue
            refElement.current.style.minHeight = updatingValue
          }
        }
      }, 100);

      window.visualViewport?.addEventListener('resize', handleResize);

      return () => {
        window.visualViewport?.removeEventListener('resize', handleResize);
      }
    }
  }, []);

  return refElement
};