import { useEffect } from 'react';
import _debounce from 'lodash/debounce';
import UAParser from 'ua-parser-js';

import { checkIsBodyFixed } from '@web-solutions/core/utils/check-is-body-fixed';

const isKeyboardElement = (elem: HTMLElement) =>
  (elem.tagName === 'INPUT' &&
    !['button', 'submit', 'checkbox', 'file', 'image'].includes(
      (elem as HTMLInputElement).type,
    )) ||
  elem.tagName === 'TEXTAREA' ||
  elem.hasAttribute('contenteditable');

const r = new UAParser().getResult();

export const useScrollOnFocus = () => {
  useEffect(() => {
    const isBodyFixed = checkIsBodyFixed();

    if (r?.os?.name === 'iOS') {
      const handleFocusIn = _debounce((e) => {
        if (e.target && isKeyboardElement(e.target as HTMLElement)) {
          if (isBodyFixed) {
            window.scrollTo({ top: 0, behavior: 'smooth' })
          } else {
            e.target?.scrollIntoView({ block: 'end', inline: 'start', behavior: 'smooth' });
          }
        }
      }, 100);

      document.addEventListener('focusin', handleFocusIn);

      return () => {
        document.removeEventListener('focusin', handleFocusIn);
      }
    }
  }, []);
};