import { forwardRef, useCallback, useState, useImperativeHandle } from 'react';
import { useDispatch } from 'react-redux';

import Analytics from '@web-solutions/module-analytics';

import { EVENT_ACTION } from '@web-solutions/core/constants/general';

import {
  handleSuccessPurchase,
  subscribeOnOneClick,
  setPending,
} from '@web-solutions/core/store/billing/actions';

import { Verify3dsModal } from '@web-solutions/core/containers/verify-3ds';

import type { Subscription } from '@web-solutions/core/interfaces/billing';
import type { PaymentError } from '@web-solutions/core/interfaces/errors';
import type { SubscribeOnOneClickParams, SubscribeOnOneClickResponse } from '@web-solutions/core/store/billing/types';

export interface SubscribeOnOneClickRef {
  subscribeOnOneClick: (data: SubscribeOnOneClickParams) => void;
}

interface SubscribeOnOneClickProps {
  onSuccess: (purchase: Subscription) => void,
  onError: (error: PaymentError) => void
}

export const SubscribeOnOneClick = forwardRef<SubscribeOnOneClickRef, SubscribeOnOneClickProps>(({ onSuccess, onError }, ref) => {
  const dispatch = useDispatch()

  const [verifyUrl, setVerifyUrl] = useState('')
  const [subscriptionData, setSubscriptionData] = useState<Subscription | null>(null);

  const handleSubscribeOnOneClick = useCallback(async (data: SubscribeOnOneClickParams) => {
    try {
      dispatch(setPending(true))

      const response = await dispatch(subscribeOnOneClick(data)) as unknown as SubscribeOnOneClickResponse

      const subscription = response?.data

      if (subscription) {
        if (response?.verify_url) {
          setVerifyUrl(response?.verify_url)
          setSubscriptionData(subscription)
        } else {
          await dispatch(handleSuccessPurchase(subscription))

          Analytics.trackEvent('subscribe_one_click', EVENT_ACTION.SUCCESS, { productId: subscription.plan_name });

          onSuccess(subscription)
        }
      }
    } catch (error) {
      const actualError = error as PaymentError

      Analytics.trackEvent('subscribe_one_click', EVENT_ACTION.ERROR, { message: actualError?.message, paymentSystem: actualError?.paymentSystem, code: actualError?.code, });

      onError(actualError)
    } finally {
      dispatch(setPending(false))
    }
  }, [dispatch, onError, onSuccess])

  useImperativeHandle(ref, () => ({
    subscribeOnOneClick(data: SubscribeOnOneClickParams) {
      handleSubscribeOnOneClick(data)
    }
  }), [handleSubscribeOnOneClick]);

  const handle3DSVerifySuccess = useCallback(async () => {
    setVerifyUrl('');
    setSubscriptionData(null)

    await dispatch(handleSuccessPurchase(subscriptionData!))

    Analytics.trackEvent('subscribe_one_click_3ds_verify', EVENT_ACTION.SUCCESS, { productId: subscriptionData?.plan_name });

    onSuccess(subscriptionData!)
  }, [subscriptionData, dispatch, onSuccess])

  const handle3DSVerifyFail = useCallback(() => {
    Analytics.trackEvent('subscribe_one_click_3ds_verify', EVENT_ACTION.ERROR, { message: '3DS verify error' });
    setVerifyUrl('');
    setSubscriptionData(null)

    onError({ code: '3DS verify', message: '3DS verify error', })
  }, [onError])

  return (
    <Verify3dsModal verifyUrl={verifyUrl} onSuccess={handle3DSVerifySuccess} onFail={handle3DSVerifyFail} />
  )
})

