import type { FC } from 'react'

import { t } from '@web-solutions/manage-subscription/src/localization';

import { TerminateTemplate } from '@web-solutions/manage-subscription/src/terminate/components/template'

import { useManageContext } from '../../../hooks/use-manage-context';

const tKey = 'terminate.success';

export const Default: FC = () => {
  const { logo, defaultCancelSuccessContent } = useManageContext();

  return (
    <TerminateTemplate
      title={t(`${tKey}.title`)}
      text={t(`${tKey}.description`)}
      logo={logo}
      subject='Cancel Manage'
    >
      {defaultCancelSuccessContent}
    </TerminateTemplate>
  )
};
