import React from 'react';
import classNames from 'classnames';

import { T } from '@web-solutions/module-localization';

import { ReactComponent as ModalIcon } from './icons/shield.svg';

import classes from './style.module.scss';

export interface Props {
  isModal?: boolean;
  className?: string
}

export const PaymentSafe: React.FC<Props> = ({ isModal = true, className }) => (
  <>
    {
      isModal ?
        (
          <span className={classNames(classes.content, classes.modalContent, className)}>
            <ModalIcon className={classes.modalIcon}/>
            <T k={'core.payment_safe'} />
          </span>
        ) :
        (
          <>
            <span className={classNames(classes.content, classes.lightContent, className)}>
              <span className={classNames(classes.icon, classes.lightIcon)}>
                <T k={'core.payment_safe'} />
              </span>
            </span>
            <span className={classNames(classes.content, classes.darkContent, className)}>
              <span className={classNames(classes.icon, classes.darkIcon)}>
                <T k={'core.payment_safe'} />
              </span>
            </span>
          </>
        )
    }
  </>
);
