import React from 'react';

import { useManageContext } from '../../hooks/use-manage-context';

export const FarewellOfferPage: React.FC = () => {
  const { farewellOfferContent } = useManageContext();
  return (
    <>
      {farewellOfferContent}
    </>
  );
};