import { Text } from "core/ui-elements";

import { T, t } from "../../../../../../module-localization";
import { Base } from "../base"

import classes from '../../style.module.scss'
import { IMAGES } from "../../images";

const tKey = 'core.subscription.error_popup.decline_reasons.invalid_data';

interface Props {
  onClick: () => void;
}

export const InvalidData: React.FC<Props> = ({ onClick }) => {
  return <Base subtitle={t(`${tKey}.subtitle`)} withButton onClick={onClick}>
    <>
      <Text className={classes.baseText}>
        <T k={`${tKey}.text`} components={{ b: <span className={classes.highlight} /> }} />
      </Text>
      <img className={classes.cardImage} src={IMAGES.IMAGE_CARD.src} srcSet={IMAGES.IMAGE_CARD.srcSet} alt="card" />
    </>
  </Base>
}