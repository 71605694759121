import _debounce from 'lodash/debounce';
import { useEffect, useState, useCallback } from "react";

import { checkIsBodyFixed } from '@web-solutions/core/utils/check-is-body-fixed';

const debounceTime = /Android/i.test(navigator.userAgent) ? 50 : 0;

export const useWindowEnd = () => {
  const [isEnd, setIsEnd] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleScroll = useCallback((el?: HTMLElement | null) => _debounce(() => {
    const condition = el ?
      el.offsetHeight + el.scrollTop >= el.scrollHeight - 10 :
      window.innerHeight + window.pageYOffset > document.body.scrollHeight - 10

    if (condition) {
      setIsEnd(true);
    } else {
      setIsEnd(false);
    }
  }, debounceTime), []);

  useEffect(() => {
    let rootEl: HTMLElement | Window = window;

    const isBodyFixed = checkIsBodyFixed();
    if (isBodyFixed) {
      rootEl = document.getElementById('root') as HTMLElement
    }

    const handler = handleScroll(isBodyFixed ? rootEl as HTMLElement : null) as EventListenerOrEventListenerObject;

    rootEl.addEventListener('scroll', handler);
    return () => {
      rootEl.removeEventListener('scroll', handler);
    };
  }, [handleScroll]);

  return { isEnd, setIsEnd, handleScroll };
};