import i18next, { type Resource } from 'i18next';
import { TransProps } from 'react-i18next';
import _merge from 'lodash/merge';

import { t as baseT, T as BaseT } from '@web-solutions/module-localization';

const resources: Resource = {
  de: require('./locales/de/index.json'),
  en: require('./locales/en/index.json'),
  'es-mx': require('./locales/es-MX/index.json'),
  es: require('./locales/es-ES/index.json'),
  fr: require('./locales/fr/index.json'),
  'pt-br': require('./locales/pt-BR/index.json'),
  pt: require('./locales/pt-PT/index.json'),
  ja: require('./locales/ja/index.json'),
  ar: require('./locales/ar/index.json'),
};

const noProofReadResources = {
  de: require('./locales/de/no_proofread.json'),
  en: require('./locales/en/no_proofread.json'),
  es: require('./locales/es-ES/no_proofread.json'),
  fr: require('./locales/fr/no_proofread.json'),
  'pt-br': require('./locales/pt-BR/no_proofread.json'),
  pt: require('./locales/pt-PT/no_proofread.json'),
  ja: require('./locales/ja/no_proofread.json'),
  ar: require('./locales/ar/no_proofread.json'),
}

const resultResources = _merge({}, resources, noProofReadResources);

const ns = '@web-solutions/face-reading';

export function initLocalization(overriding?: any) {
  for (const k in resultResources) {
    i18next.addResourceBundle(k, ns, resultResources[k], true, false);
    if (overriding?.[k]) {
      i18next.addResourceBundle(k, ns, overriding[k], true, true);
    }
  }
}

type TProps = Omit<TransProps<any>, "i18n" | "i18nKey">

export const T = ({ k, tOptions, ...other }: { k: string, } & TProps) => {
  return <BaseT
    k={k}
    tOptions={tOptions}
    //@ts-ignore
    ns={ns}
    {...other}
  />
}

export function t(key: string, options?: object): string {
  return baseT(ns + ':' + key, options);
}
