import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  selectedAnswers: {},
};


const slice = createSlice({
  name: 'quiz',
  initialState,
  reducers: {
    setSelectedAnswers: (state, action: PayloadAction<any>) => {
      state.selectedAnswers = {
        ...state.selectedAnswers,
        ...action.payload,
      }
    },
  }
})

export { slice as quizSlice };

