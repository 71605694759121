import _mergeWith from 'lodash/mergeWith'
import _cloneDeep from 'lodash/cloneDeep'

import type { ButtonConfig } from '@web-solutions/core/payment/constants';

type Params<T = ButtonConfig> = {
  config: T,
  updatingFields: Partial<T>,
}

export const updateButtonConfig = <T>({ config, updatingFields }: Params<T>) => _mergeWith(_cloneDeep(config), updatingFields, (cur, inc) => {
  if (typeof inc === 'string' && inc === '') {
    return cur
  }
})