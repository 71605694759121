let paymentSystem;
export function setPaymentSystem(type) {
  paymentSystem = type;
}
export function getPaymentSystem() {
  return paymentSystem;
}

let publicKeys;
export function setPublicKeys(keys) {
  publicKeys = keys;
}
export function getPublicKeys() {
  return publicKeys;
}
