import { AxiosResponse } from 'axios'

import request from '@web-solutions/core/utils/network';

import type { ProfileResponse, UpdateProfileApiProps } from './types'

export function updateProfile({ id, data }: UpdateProfileApiProps): Promise<AxiosResponse<ProfileResponse, any>> {
  return request.patch(`/users/${id}`, { profile_data: data?.profile_data ? data?.profile_data : null })
}

export function getProfile(): Promise<AxiosResponse<ProfileResponse, any>> {
  return request.get('/user/profile')
}