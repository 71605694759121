import _debounce from 'lodash/debounce';
import { useEffect, useState } from 'react';

interface UseHeightOptions {
  withoutScroll?: boolean;
}

export const useHeight = (options?: UseHeightOptions) => {
  const [containerHeight, setContainerHeight] = useState(() => typeof window !== 'undefined' ? window.innerHeight : 0);

  const setHeight = _debounce(() => {
    setContainerHeight(window.innerHeight);
  }, 100);

  useEffect(() => {
    if (!options?.withoutScroll) {
      window.scrollTo(0, 0);
    }
    setHeight();
    window.addEventListener('resize', setHeight);
    return () => window.removeEventListener('resize', setHeight);
  }, []);

  return containerHeight;
};
