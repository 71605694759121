import { useRef, useEffect } from 'react';
import _debounce from 'lodash/debounce';
import UAParser from 'ua-parser-js';

const r = new UAParser().getResult();

export const useSetKeyboardInsetHeightVar = () => {
  const refInnerHeight = useRef(window.innerHeight);

  useEffect(() => {
    if (r?.os?.name === 'iOS') {
      const handleResize = _debounce(() => {
        document.documentElement.style.setProperty(
          "--keyboard-inset-height",
          `${(window.visualViewport?.height ?? 0) <= refInnerHeight.current ? refInnerHeight.current - Math.round(window.visualViewport?.height ?? 0) : 0}px`
        );
      }, 100);

      window.visualViewport?.addEventListener('resize', handleResize);

      return () => {
        window.visualViewport?.removeEventListener('resize', handleResize);
      }
    }
  }, []);
};