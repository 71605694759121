import { useCallback } from "react";
import { useDispatch } from 'react-redux';

import Analytics from "@web-solutions/module-analytics";

import {
  applePayOrder,
} from '@web-solutions/core/store/billing/actions';

import { EVENT_ACTION } from "@web-solutions/core/constants/general";
import { PaymentSystem } from "@web-solutions/react-billing/constants";

import { useShowApplePay } from "@web-solutions/core/hooks/use-show-payment-method";

import type { PaymentError } from "@web-solutions/core/interfaces/errors";
import type { Subscription } from '@web-solutions/core/interfaces/billing';

import type { PaymentProcessorProps } from '../'

const ANALYTICS = 'apple_pay'

interface Props extends Pick<PaymentProcessorProps, 'orderSolidMetadata' | 'orderMetadata'> {
  paymentSystem: PaymentSystem,
  handleSubmit: (formData: any) => void,
  handlePaymentSuccess: (purchase: Subscription) => Promise<void>,
  handleCardPaymentError: (error: PaymentError) => void
  trigger?: string,
}

export const useApplePayProcessor = ({
  paymentSystem,
  trigger,
  orderMetadata,
  orderSolidMetadata,
  handleSubmit,
  handlePaymentSuccess,
  handleCardPaymentError
}: Props) => {
  const dispatch = useDispatch();

  const showApplePay = useShowApplePay();

  const handleApplePayClick = useCallback(() => {
    Analytics.trackEvent(ANALYTICS, EVENT_ACTION.CLICK);
  }, []);

  const handleApplePaySubmit = useCallback(async (formData: any) => {
    Analytics.trackEvent(ANALYTICS, EVENT_ACTION.SUBMIT);

    await handleSubmit(formData);

    if (process.env.REACT_APP_APPLE_PAY_H2H) {
      const ps = formData.paymentSystem || paymentSystem;

      if (ps === PaymentSystem.SOLIDGATE) {
        dispatch(applePayOrder(formData, {
          trigger,
          metadata: orderMetadata,
          solidMetadata: orderSolidMetadata,
        }))
          //@ts-ignore
          .then((purchase: Subscription) => {
            handlePaymentSuccess(purchase);
          })
          .catch((error: PaymentError) => {
            handleCardPaymentError(error);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    paymentSystem,
    dispatch,
    handleCardPaymentError,
    handlePaymentSuccess,
    handleSubmit
  ]);

  return {
    showApplePay,
    handleApplePayClick,
    handleApplePaySubmit
  }
}