import { MercadoPagoBtn } from "core/payment/components/mercado-pago"
import { ProductDetails } from "core/store/billing/selectors";

interface MercadoContainerProps {
  activeProduct: ProductDetails;
  isButtonVisible: boolean;
  upsaleTrigger?: string;
}

export const MercadoContainer: React.FC<MercadoContainerProps> = ({
  isButtonVisible,
  activeProduct,
  upsaleTrigger,
}) => {

  if (!isButtonVisible) {
    return null;
  }

  return <>
    <MercadoPagoBtn
      product={activeProduct}
      upsaleTrigger={upsaleTrigger}
    />
  </>
}