import { useRef, useEffect } from "react";

export const useAutofocus = <T extends HTMLElement>() => {
  const ref = useRef<T>(null);

  useEffect(() => {
    ref.current && ref.current.focus({ preventScroll: true })
  }, [])

  return ref
}