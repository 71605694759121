import React, { useEffect } from 'react';

import Analytics from '@web-solutions/module-analytics';
import FixedButtonsInWrapper from '@web-solutions/manage-subscription/src/manage/components/fixed-buttons-in-wrapper';
import { useRemoteConfig } from '@web-solutions/core/hooks/use-remote-config';
import { EVENT_ACTION } from '@web-solutions/core/constants/general';

import { t } from '../../../localization';

import { goAppLink, useSliceDispatch } from '../../slice';

import { useNavigateManage } from '../../hooks/use-navigate-next-screen';
import { useManageContext } from '../../hooks/use-manage-context';
import { Header } from '../../components/header';
import { ErrorModal } from '../../components/error-modal';

import { useUnsubscribe } from '../../hooks/use-unsubscribe';

import classes from './style.module.scss';

const tKey = 'manage.benefits';
const category = 'manage_benefits';

export const ManageBenefitsPage: React.FC = () => {
  const dispatch = useSliceDispatch();
  const { mngSubBenefitsPage, isShortManageVariant } = useRemoteConfig();
  const { fixedLink, fixedButton } = mngSubBenefitsPage;
  const { navigateNextScreen, navigatePrevSceen } = useNavigateManage();
  const { benefitsContent } = useManageContext();
  const { unsubscribe, setErrorMessage, errorMessage } = useUnsubscribe(category);

  useEffect(() => {
    Analytics.trackEvent(category, EVENT_ACTION.OPEN);
  }, []);

  const handleBackClick = () => {
    navigatePrevSceen();
  }

  const handleKeepClick = () => {
    Analytics.trackEvent(category, 'return_to_app');
    dispatch(goAppLink());
  };

  const handleContinueClick = () => {
    if (isShortManageVariant) {
      Analytics.trackEvent(category, 'continue', { unsubscribe: true });
      unsubscribe();
    } else {
      Analytics.trackEvent(category, 'continue');
      navigateNextScreen();
    }
  };

  const handleErrorCloseClick = () => {
    Analytics.trackEvent(`${category}_error_modal`, EVENT_ACTION.CLOSE);
    setErrorMessage('');
  }

  const handleRetryClick = () => {
    Analytics.trackEvent(`${category}_terminate_retry`, EVENT_ACTION.CLICK);
    setErrorMessage('')
    unsubscribe();
  }

  return (
    <div className={classes.wrap}>
      <Header
        titleClassName={classes.title}
        subtitleClassName={classes.subtitle}
        title={t(`${tKey}.title`)}
        subtitle={t(`${tKey}.subtitle`)}
        onBackClick={handleBackClick}
        withBackButton
      />

      {benefitsContent}

      <footer>
        <FixedButtonsInWrapper
          isFixedLink={fixedLink}
          isFixedButton={fixedButton}
          buttonTitle={t(`${tKey}.return_to_app`)}
          linkTitle={t(`${tKey}.continue`)}
          onButtonClick={handleKeepClick}
          onLinkClick={handleContinueClick}
        />
      </footer>
      <ErrorModal
        tKey={`${tKey}.error_modal`}
        isOpen={!!errorMessage}
        message={errorMessage}
        onButtonClick={handleRetryClick}
        onCloseClick={handleErrorCloseClick}
      />
    </div>
  );
};
