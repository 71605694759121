import { t, tm } from '@web-solutions/module-localization';

import { ButtonConfig } from 'core/payment/constants';

export const getButtonTitleByConfig = (config: ButtonConfig, defaultTKeyText: string, details?: object) => {
  let { uppercaseTitle, title } = (config || {});

  if (title && typeof title === 'string' && title[0] === '_') {
    return t(title);
  }

  title = tm(title, defaultTKeyText, details) as string;

  return uppercaseTitle ? title.toUpperCase() : title;
};
