import { useCallback, useEffect, useState } from 'react';
import * as dayjs from 'dayjs';
import _isNaN from 'lodash/isNaN';

import useInterval from './use-interval';

const prepare = (time) => {
  const dur = dayjs(time).diff(dayjs(), 'seconds');
  const minutes = Math.floor(dur / 60);
  const seconds = dur - minutes * 60;
  return { seconds, minutes };
}

const useTimer = (endTime) => {
  const [data, setData] = useState(() => prepare(endTime));

  useEffect(() => {
    setData(prepare(endTime))
  }, [endTime])

  const { minutes, seconds } = data || {}

  const delay = ((_isNaN(minutes) || _isNaN(seconds)) && !endTime) || (seconds < 0 || minutes < 0) ? null : 1000

  const callback = useCallback(() => {
    setData(prepare(endTime))
  }, [endTime])

  useInterval(callback, delay);

  return data;
};

export default useTimer;
