import type { ImagesMap } from "@web-solutions/core/interfaces/images";

import HAND_1X from './hand@1x.webp'
import HAND_2X from './hand@2x.webp'
import HAND_3X from './hand@3x.webp'

export const IMAGES: ImagesMap = {
  HAND: {
    src: HAND_1X,
    srcSet: `${HAND_1X}, ${HAND_2X} 2x, ${HAND_3X} 3x`
  }
}