import { t } from '@web-solutions/module-localization';

import { TopHourGlass, Triangle, Spoon, Rectangle, InvertedTriangle, HourGlass, BottomHourGlass } from './icons';

export const tKey = 'summary.personal_summary';

export const EYES_COLORS = {
  "brightBlue": "#428CC3",
  "lightBlue": "#A0C4E4",
  "brightGreen": "#50B064",
  "lightGreen": "#A4D3A2",
  "gray": "#909F9F",
  "amber": "#B76F2A",
  "lightHazel": "#A69A54",
  "darkHazel": "#514926",
  "lightBrown": "#B78E5E",
  "darkBrown": "#45261A",
  "mutedBrown": "#75351C",
  "black": "#0F0F0F"
}

export const SKIN_TONE = {
  "coolPale": "#F1DFDC",
  "porcelain": "#EBDED4",
  "ivory": "#ECCEB6",
  "peach": "#FAD1A1",
  "rosyBeige": "#D9A799",
  "neutralBeige": "#D2B8A3",
  "goldenBeige": "#D7B28C",
  "olive": "#BEA678",
  "tan": "#C38B70",
  "bronze": "#A05F42",
  "chocolate": "#754137",
  "espresso": "#4B2020",
}

export const HAIR_COLORS = {
  "ashBlonde": "#C5B8A5",
  "goldenBlonde": "#D2B48C",
  "ashBrown": "#6E5C4E",
  "coolBrown": "#56473D",
  "brown": "#50321D",
  "warmBrown": "#6E3C19",
  "strawberryBlonde": "#E8A87C",
  "copper": "#CA804C",
  "auburn": "#873923",
  "darkAuburn": "#581B07",
  "brownBlack": "#2B2620",
  "black": "#0C0C0C",
}

export const SHAPES = {
  topHourglass: {
    component: <TopHourGlass />,
    title: t(`${tKey}.shape.shapes.topHourGlass`)
  },
  bottomHourglass: {
    component: <BottomHourGlass />,
    title: t(`${tKey}.shape.shapes.bottomHourGlass`)
  },
  hourglass: {
    component: <HourGlass />,
    title: t(`${tKey}.shape.shapes.hourGlass`)
  },
  invertedTriangle: {
    component: <InvertedTriangle />,
    title: t(`${tKey}.shape.shapes.invertedTriangle`)
  },
  rectangle: {
    component: <Rectangle />,
    title: t(`${tKey}.shape.shapes.rectangle`)
  },
  spoon: {
    component: <Spoon />,
    title: t(`${tKey}.shape.shapes.spoon`)
  },
  triangle: {
    component: <Triangle />,
    title: t(`${tKey}.shape.shapes.triangle`)
  },
}