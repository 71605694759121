import type { FC, ComponentProps, ReactNode } from 'react'
import classNames from 'classnames'

import { Text } from '@web-solutions/core/ui-elements'

import classes from './style.module.scss'

interface DescriptionOptionsProps extends ComponentProps<'div'> {
  options: string[]
  title?: string,
  lastOption?: ReactNode
}

export const DescriptionOptions: FC<DescriptionOptionsProps> = ({ className, title, options, lastOption, ...props }) =>
  <div className={classNames(classes.optionsWrapper, className)} {...props}>
    {title && (
      <Text className={classes.title}>
        {title}
      </Text>
    )}
    <ul className={classes.list}>
      {options.map((option, index) =>
        <li className={classes.option}>
          <Text className={classes.optionText}>
            {index + 1 === options.length && lastOption ?
              lastOption :
              option
            }
          </Text>
        </li>
      )}
    </ul>
  </div>         