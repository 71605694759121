import { useCallback } from "react";

import { useShowPayPal } from "@web-solutions/core/hooks/use-show-payment-method";

import type { ProductDetails } from "@web-solutions/core/store/billing/selectors";

interface Props {
  activeProduct: ProductDetails,
  onSubmit: (formData: any) => Promise<void>
}

export const usePayPalProcessor = ({ activeProduct, onSubmit }: Props) => {
  const showPayPal = useShowPayPal(activeProduct);

  const handlePayPalSubmit = useCallback((formData: any) => onSubmit(formData), [onSubmit]);

  return {
    showPayPal,
    handlePayPalSubmit
  }
}