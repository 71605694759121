import { Currency } from "@web-solutions/react-billing/constants";
import { COUNTRY_VAT } from "@web-solutions/core/payment/constants";

import { getCurrencySymbols, getPreparedPrice } from "@web-solutions/react-billing/utils/prices";

type Params = {
  amount: string | number,
  currency: Currency,
  countryCode: string,
  displayVAT?: boolean
}

export const createVATPrice = ({ amount, currency, countryCode, displayVAT }: Params) => {
  const country = countryCode.toLowerCase();
  const { currencySuffix, currencySymbol } = getCurrencySymbols(currency);

  if (!COUNTRY_VAT[country] || !displayVAT) {
    return { vat: '0', price: `${currencySymbol}${amount}${currencySuffix}` }
  }

  const { vat: vatPercent } = COUNTRY_VAT[country]

  const price = `${currencySymbol}${getPreparedPrice(+amount / (100 + vatPercent) * 100)}${currencySuffix}`
  const vat = `${currencySymbol}${getPreparedPrice(+amount * (vatPercent / (vatPercent + 100)))}${currencySuffix}`

  return { vat, price }
}