import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { t } from '@web-solutions/module-localization';

import { ApplePayButton } from '@web-solutions/react-billing';
import { selectPaymentSystem } from '@web-solutions/core/store/billing/selectors';

import { getIsRenderAvailable } from './utils'

import classes from './style.module.scss';
import './style.scss';

const ApplePay = ({
  activeProduct,
  className,
  onApplePayClick,
  onApplePaySubmit,
}) => {
  const email = useSelector((state) => state.profile.email);
  const paymentSystem = useSelector(selectPaymentSystem);

  const APP_NAME = t('core.appName');

  const isRenderAvailable = getIsRenderAvailable(paymentSystem);

  const handleSubmit = (formData) => {
    onApplePaySubmit({ email, ...formData });
  }

  return isRenderAvailable
    ? (
      <ApplePayButton
        product={activeProduct}
        label={APP_NAME}
        requireEmail={!email}
        className={classNames(classes.btn, className)}
        onClick={onApplePayClick}
        onSubmit={handleSubmit}
      />
    )
    : null;
}

export default ApplePay;
