import { t } from '@web-solutions/module-localization';

import type { ProductDetails } from "@web-solutions/core/store/billing/selectors"

import type { RemoteConfig } from "@web-solutions/core/constants/remote-config"

type Params = {
  product: ProductDetails,
  periodMode: RemoteConfig['trialInfoBlock']['periodMode']
}

const tKey = 'core.payment_popup.trial_info_block'

export const useGetTrialPeriodByMode = ({ periodMode, product }: Params) => periodMode === 'day' || product.trialDays % 7 !== 0 ? `${product.trialDays}-${t(`${tKey}.day`)}` : `${product.trialDays / 7}-${t(`${tKey}.week`)}`;