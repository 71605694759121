import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import _intersection from 'lodash/intersection'
import _uniq from 'lodash/uniq'

import { selectPaymentSystem } from '@web-solutions/core/store/billing/selectors';

import { InjectedPaymentMethod } from '@web-solutions/core/constants/remote-config';

import { useRemoteConfig } from '@web-solutions/core/hooks/use-remote-config';
import { PaymentSystem } from '@web-solutions/react-billing';

const REQUIRED_SOLIDGATE_CONTAINERS_FOR_RENDERING = [InjectedPaymentMethod.APPLEPAY, InjectedPaymentMethod.GOOGLEPAY]

export const useGetOrderedPaymentMethods = () => {
  const { injectedPaymentMethods } = useRemoteConfig();

  const paymentSystem = useSelector(selectPaymentSystem);

  const paymentMethods = useMemo(() => {
    const flattedInjectedPaymentMethods = injectedPaymentMethods.flat()

    return _intersection(paymentSystem === PaymentSystem.SOLIDGATE ? _uniq([...flattedInjectedPaymentMethods, ...REQUIRED_SOLIDGATE_CONTAINERS_FOR_RENDERING]) : flattedInjectedPaymentMethods, Object.values(InjectedPaymentMethod) as InjectedPaymentMethod[])
  }, [injectedPaymentMethods, paymentSystem]);

  return paymentMethods
}