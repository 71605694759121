import type { FC } from 'react';

import { t } from '../../localization';

import { TerminateTemplate } from '../components/template';

import { useTerminateContext } from '../hooks/use-terminate-context';

const tKey = 'terminate.success';

export const TerminateSuccessScreen: FC = () => {
  const { logo, terminateSuccessContent } = useTerminateContext();

  return (
    <TerminateTemplate
      title={t(`${tKey}.title`)}
      text={t(`${tKey}.description`)}
      logo={logo}
    >
      {terminateSuccessContent}
    </TerminateTemplate >
  )
};
