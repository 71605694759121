import React from 'react';
import classNames from 'classnames';

import classes from './style.module.scss';

import { ReactComponent as Icon } from './icons/preloader.svg';

interface Props {
  className?: string
}

const Preloader: React.FC<Props> = ({ className }) => (
  <div className={classNames(classes.wrapperSpinner, className)}>
    <span className={classes.spinner}>
      <Icon width="50px" height="50px" />
    </span>
  </div>
);

export default Preloader;
