import { type FC, useEffect } from 'react'

import Analytics from '@web-solutions/module-analytics';

import { EVENT_ACTION } from '@web-solutions/core/constants/general';

import { useScrollOnFocus } from '@web-solutions/core/hooks/use-scroll-on-focus';

import { t } from '../../../localization';

import { Header } from '../../components/header';

import { useNavigateManage } from '../../hooks/use-navigate-next-screen';

import { baseTKey, ANALYTICS } from './contants'

import { Form } from './components'

import { useUpdateWrapperSize } from './hooks'

import classes from './style.module.scss';

export const Review: FC = () => {
  const { navigatePrevSceen } = useNavigateManage();

  useScrollOnFocus();

  const ref = useUpdateWrapperSize<HTMLDivElement>({ maxHeight: 'initial', minHeight: '100svh' });

  useEffect(() => {
    Analytics.trackEvent(ANALYTICS, EVENT_ACTION.OPEN);
  }, []);

  const handleBackClick = () => {
    Analytics.trackEvent(ANALYTICS, EVENT_ACTION.BACK);

    navigatePrevSceen();
  }

  return (
    <div ref={ref} className={classes.reviewWrapper}>
      <Header
        withBackButton
        title={t(`${baseTKey}.title`)}
        subtitle={t(`${baseTKey}.subtitle`)}
        titleClassName={classes.title}
        onBackClick={handleBackClick}
      />
      <Form />
    </div>
  )
}
