import type { FC } from 'react'

import { useRemoteConfig } from '@web-solutions/core/hooks/use-remote-config';

import { WithoutLogo } from './without_logo'
import { Default } from './default'

export const CancelSuccessPage: FC = () => {
  const { mngSubCancelSuccessPage } = useRemoteConfig();

  if (mngSubCancelSuccessPage.mode === 'without_logo') {
    return <WithoutLogo />
  }

  return <Default />
};
