import classNames from "classnames"
import _map from 'lodash/map';

import { t } from "@web-solutions/module-localization";

import PaymentForm from "core/payment/components/payment-form";

import { ICONS } from '../../icons/cards';
import { ReactComponent as IconArrowDown } from '../../icons/icon-arrow-down.svg';
import { ReactComponent as IconCreditCard } from '../../icons/credit-card.svg';

import classes from '../../style.module.scss';

interface CardContainerProps {
  showApplePay: boolean;
  showGooglePay: boolean;
  showPayPal: boolean;
  showCardForm: boolean;
  isVisible: boolean;
  injectedCard: boolean;
  isSimpleView: boolean;
  formWrapStyle: string;
  expandWrapStyle: string;
  cardFormProps: any;
  handlePayWithCardClick: (e: any) => void;
}

const tKey = 'core.payment_popup';

export const CardContainer: React.FC<CardContainerProps> = ({
  showCardForm,
  showApplePay,
  showGooglePay,
  showPayPal,
  injectedCard,
  isVisible,
  isSimpleView,
  formWrapStyle,
  expandWrapStyle,
  cardFormProps,
  handlePayWithCardClick
}) => {

  return <div className={classNames(formWrapStyle, {
    [classes.hidden]: !isVisible,
    [classes.simple_view]: isSimpleView,
  })}>
    <button
      type="button"
      onClick={handlePayWithCardClick}
      className={classes.payWithCard}
    >
      <IconCreditCard width={30} height={21} className={classes.icon_card} />
      <p className={classes.payWithCardText}>{t(`${tKey}.pay_with_card`)}</p>
      {(showApplePay || showPayPal || showGooglePay) && !injectedCard && (
        <div className={classes.arrowDownWrap}>
          <IconArrowDown
            width={12}
            height={6}
            className={classNames(classes.iconArrow, {
              [classes.iconArrowUp]: showCardForm,
            })}
          />
        </div>
      )}
    </button>
    <div className={expandWrapStyle}>
      <ul className={classes.cardsList}>
        {_map(ICONS, (Icon, key) => (
          <li key={key} className={classes.cardItem}>
            <Icon width="100%" height="100%" />
          </li>
        ))}
      </ul>
      <PaymentForm {...cardFormProps} />
    </div>
  </div>
}