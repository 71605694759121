import { useEffect, useRef } from 'react';

const useInterval = (callback, delay) => {
  const savedCallback = useRef();
  const interval = useRef()

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (delay !== null) {
      const tick = () => {
        savedCallback.current();
      };
      interval.current = setInterval(tick, delay);
      return () => interval.current && clearInterval(interval.current);
    }

    return () => interval.current && clearInterval(interval.current);
  }, [delay]);
};

export default useInterval
