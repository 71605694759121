import _mapValues from 'lodash/mapValues';
import _merge from 'lodash/merge';
import _pick from 'lodash/pick';
import _omit from 'lodash/omit';
import queryString from 'query-string';

import DeviceProps from '@magnus/react-native-device-props';
import EvTruck from '@magnus/react-native-evtruck';
import { type IAttribution } from '@magnus/react-native-evtruck/types/interfaces';

import Web2App from '@wowmaking/web2app';

import { disabled } from '@web-solutions/module-analytics';

export type AppLinks = {
  ADJUST_LINK?: string;
  ADJUST_TOKEN?: string;
  APPSFLYER_LINK?: string;
  WEB_APP_LINK?: string;
}

type URLBaseParamsType = {
  urlExtraPath?: string;
  query?: Record<string, string>
}

let IDFM: string | undefined;
let appLinks: AppLinks | undefined;
let rcTarget: LinkTarget | undefined;

let URLBaseParams: URLBaseParamsType = {
  urlExtraPath: '',
  query: {}
};


export enum LinkTarget {
  DEFAULT = 'default', // empty - by default - go to AF/Adjust link and then to store
  WEB = 'web', // target is web app
}


const UTM_PARAMS = [
  'utm_source',
  'campaign_name',
  'campaign_id',
  'adset_name',
  'adset_id',
  'ad_name',
  'ad_id',
  'ad_subnetwork',
  'ad_keyword',
  'ad_placement',
];

const CLID_PARAMS = [
  'ttclid',
  'fbclid',
  'gbraid',
  'wbraid',
  'gclid',
  'gad_source',
  'ScCid',
  'ai',
  'epik',
  'utm_content',
  'utm_medium',
  'utm_term',
  'utm_id',
];

const AttributionModule = {
  setAppLinks(links: AppLinks) {
    appLinks = links;
  },
  init(links: AppLinks) {
    appLinks = links;

    DeviceProps.getIDFM().then((idfm) => {
      IDFM = idfm;
    });

    const params = queryString.parse(window.location.search);

    const attribution = _mapValues(_pick(params, UTM_PARAMS), i => String(i || '')) as unknown as IAttribution;

    if (!disabled) {
      EvTruck.trackWebAttribution({
        ...attribution,
        http_referer: window.document.referrer || '',
        url: queryString.stringifyUrl({
          url: window.location.origin + window.location.pathname,
          query: _omit(params, [...UTM_PARAMS, ...CLID_PARAMS, 'idfm', 'email']),
        }),
      });

      Web2App.init({
        onChange: (system, p) => {
          // оборачиваем в массив, в будущем сможем поддерживать несколько пикселей на сайте и тп
          EvTruck.trackWebMeasurements({
            external_id: Web2App.getAttribution().web_external_id,
            [system]: [p],
          });
        },
      });

      if (appLinks.ADJUST_LINK && appLinks.ADJUST_TOKEN) {
        return Web2App.trackAdjustImpression(appLinks.ADJUST_TOKEN);
      }
    }
  },

  setURLBaseParams(params: URLBaseParamsType) {
    URLBaseParams = _merge(URLBaseParams, params)
  },

  setRemoteConfigTarget(target: LinkTarget) {
    rcTarget = target;
  },

  getMailLinkTarget(url: string) {
    return appLinks?.WEB_APP_LINK && url.indexOf(appLinks?.WEB_APP_LINK) === 0 ? LinkTarget.WEB : undefined;
  },

  getLinkToApp(linkTarget?: LinkTarget) {
    const source = 'sub';
    const params = queryString.parse(window.location.search);

    linkTarget = linkTarget || rcTarget;

    if (!appLinks) {
      throw Error('Cannot create app link');
    }

    const query = {
      source,
      mode: params.mode,
      utm_source: params.utm_source,
      campaign_id: params.campaign_id,
      ua_creative_topic: params.creative_topic,
    }

    const w = appLinks.WEB_APP_LINK
      ? queryString.stringifyUrl({
        url: `${appLinks.WEB_APP_LINK}${URLBaseParams.urlExtraPath}`,
        query: {
          idfm: IDFM,
          ...query,
          ...URLBaseParams.query
        },
      })
      : null;

    if (linkTarget === LinkTarget.WEB && w) {
      return w;
    } else if (appLinks.APPSFLYER_LINK) {
      return Web2App.createAppsFlyerLink(appLinks.APPSFLYER_LINK, {
        uuid: IDFM,
        ...query,
        is_retargeting: true,
      });
    } else if (appLinks.ADJUST_LINK && appLinks.ADJUST_TOKEN) {
      return Web2App.createAdjustLink(appLinks.ADJUST_LINK, appLinks.ADJUST_TOKEN, {
        uuid: IDFM,
        ...query,
      });

    } else {
      throw Error('Cannot create app link');
    }
  },
};

export default AttributionModule;
