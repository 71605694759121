import type { BaseSyntheticEvent, FC, MouseEvent as RMouseEvent } from 'react'
import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux'
import classNames from 'classnames';

import {
  TICKET_PRIORITY,
  postTicket,
  type IResponseTicketData,
} from '@wowmaking/helpdesk';

import Analytics from '@web-solutions/module-analytics';
import { t } from '@web-solutions/module-localization';

import { EVENT_ACTION } from '@web-solutions/core/constants/general';

import { Title, Button, Input, Modal } from '@web-solutions/core/ui-elements';

import { useHeight } from '@web-solutions/core/hooks/use-height';
import { useGetDeviceInfoData } from '@web-solutions/core/hooks/use-get-device-info-data';

//@ts-ignore
import { PROJECT_SUPPORT_SUBJECT } from 'src/constants/general'

import { ArrowIcon, InfoIcon } from './icons'

import { SuccessPopup } from './success-popup'

import classes from './style.module.scss';

interface ContactUsModalProps {
  active: boolean;
  openLocation: string;
  onCloseModal: () => void;
  onCloseModalAfterSubmit: () => void;
}

const tKey = 'core.contact_modal';
const eventName = 'contact_modal'

const initialFormData: Record<string, string> = {
  name: '',
  email: '',
  category: '',
  subject: '',
  message: '',
}

export const ContactUsModal: FC<ContactUsModalProps> = ({ active, openLocation, onCloseModal, onCloseModalAfterSubmit }) => {
  const [activeDropdown, setActiveDropdown] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false)
  const [data, setData] = useState(() => initialFormData);

  const email = useSelector((state: any) => state?.profile?.email) ?? '';

  const deviceInfoData = useGetDeviceInfoData();

  useEffect(() => {
    if (active) {
      Analytics.trackEvent(eventName, EVENT_ACTION.OPEN, { openLocation })
    }
  }, [active, openLocation])

  useEffect(() => {
    setData((prev) => ({ ...prev, email }))
  }, [email])

  const containerHeight = useHeight();

  const handleSubmit = (e: BaseSyntheticEvent) => {
    e.preventDefault();

    Analytics.trackEvent(eventName, EVENT_ACTION.SUBMIT, { openLocation })

    const { email, subject, message, name, category } = data;

    postTicket({
      ticket: {
        subject: PROJECT_SUPPORT_SUBJECT + subject,
        mail: email,
        comment: { body: message },
        custom_fields: { ...deviceInfoData, category },
        requester: { name: name || 'User', email },
        priority: TICKET_PRIORITY.high,
        assignment: {
          agentID: '',
          teamID: process.env.REACT_APP_HELPDESK_TEAM_ID || '',

        },
        ...(deviceInfoData.paid === 'true' && process.env.REACT_APP_HELPDESK_TAG_ID && {
          tagIDs: [process.env.REACT_APP_HELPDESK_TAG_ID]
        })
      },
    }).then((data: IResponseTicketData) => {
      Analytics.trackEvent(eventName, EVENT_ACTION.SUCCESS, { openLocation })

      setData({ ...initialFormData, email });
    }).catch((err) => {
      Analytics.trackEvent(eventName, EVENT_ACTION.ERROR, { openLocation })

      console.error(err);
    })

    window.scrollTo({ top: 0, behavior: 'smooth' });
    onCloseModalAfterSubmit()
    setShowSuccessPopup(true)
  }

  const handleClose = () => {
    Analytics.trackEvent(eventName, EVENT_ACTION.CLOSE, { openLocation })

    onCloseModal()
  }

  const handleDropdownClick = () => {
    setActiveDropdown(!activeDropdown);
  }

  const handleSelectCategory = (e: RMouseEvent<HTMLDivElement, MouseEvent>) => {
    const target = e.target as HTMLDivElement;

    setData({ ...data, category: target.id });
  }

  const handleChange = (e: BaseSyntheticEvent) => {
    const { name, value } = e.target;

    setData({ ...data, [name]: value });
  }

  const handleCloseSuccessPopup = useCallback(() => {
    setShowSuccessPopup(false)
  }, [])

  return (
    <>
      <Modal
        className={classes.container}
        isOpen={active}
        overlayClassName={classes.overlay}
      >
        <button type='button' className={classes.arrow_button} onClick={handleClose}>
          <ArrowIcon className={classes.arrow} />
        </button>
        <form className={classes.form} onSubmit={handleSubmit} style={{ height: containerHeight }}>
          <header>
            <Title level='h2' className={classes.title}>{t(`${tKey}.title`)}</Title>
          </header>
          <div className={classes.tooltip}>
            <InfoIcon className={classes.info_icon} />
            <p className={classes.desc}>{t(`${tKey}.desc`)}</p>
          </div>

          <div className={classes.field_wrap}>
            <p className={classes.name}>{t(`${tKey}.fields.name.title`)}</p>
            <Input
              autoComplete="off"
              type='text'
              name='name'
              value={data.name}
              placeholder={t(`${tKey}.fields.name.placeholder`)}
              className={classes.field}
              onChange={handleChange}
            />
          </div>
          <div className={classes.field_wrap}>
            <p className={classes.name}>{t(`${tKey}.fields.email.title`)}</p>
            <Input
              autoComplete="off"
              type='email'
              name='email'
              value={data.email}
              placeholder={`${t(`${tKey}.fields.email.placeholder`)}@gmail.com`}
              className={classes.field}
              onChange={handleChange} />
          </div>
          <div className={classes.field_wrap}>
            <p className={classes.name}>{t(`${tKey}.fields.category.title`)}</p>
            <div className={classNames(classes.field, {
              [classes.active]: activeDropdown
            })}
              onClick={handleDropdownClick}>
              <p className={classNames(classes.placeholder, {
                [classes.another_color]: data.category
              })}>
                {data.category ? t(`${tKey}.fields.category.dropdown.${data.category}`) : t(`${tKey}.fields.category.placeholder`)}
              </p>
              <div className={classes.dropdown} onClick={handleSelectCategory}>
                <p id='BUG' >{t(`${tKey}.fields.category.dropdown.BUG`)}</p>
                <p id='FEEDBACK'>{t(`${tKey}.fields.category.dropdown.FEEDBACK`)}</p>
                <p id='SUBSCRIPTION'>{t(`${tKey}.fields.category.dropdown.SUBSCRIPTION`)}</p>
                <p id='OTHER'>{t(`${tKey}.fields.category.dropdown.OTHER`)}</p>
              </div>
            </div>
          </div>
          <div className={classes.field_wrap}>
            <p className={classes.name}>{t(`${tKey}.fields.subject.title`)}</p>
            <Input
              autoComplete="off"
              type='text'
              name='subject'
              value={data.subject}
              placeholder={t(`${tKey}.fields.subject.placeholder`)}
              className={classes.field}
              onChange={handleChange}
            />
          </div>
          <div className={classes.field_wrap}>
            <p className={classes.name}>{t(`${tKey}.fields.message.title`)}</p>
            <textarea
              placeholder={t(`${tKey}.fields.message.placeholder`)}
              name='message'
              value={data.message}
              className={classNames(classes.field, classes.message)}
              onChange={handleChange}
            />
          </div>
          <Button
            type='submit'
            disabled={data.email && data.category && data.message ? false : true}
            className={classes.button}
            title={t(`${tKey}.button_text`)}
          />
        </form>
      </Modal>
      <SuccessPopup active={showSuccessPopup} onClosePopup={handleCloseSuccessPopup} />
    </>
  )
}