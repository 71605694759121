
import { useSelector } from 'react-redux';

import { remoteConfigSelector } from 'core/store/remote-config/selectors';

import { RemoteConfig } from 'core/constants/remote-config';

export const useRemoteConfig = () => {
  return useSelector(remoteConfigSelector) as RemoteConfig;
}
