import _intersection from 'lodash/intersection';
import { createSelector } from 'reselect';

import normalizeBlocks from 'core/utils/normalize-blocks';

import { STEPS } from 'src/screens/quiz/questions';
import { QUIZ } from 'src/constants/routes';

const ROUTES = Object.keys(STEPS).concat(Object.keys(QUIZ));

//@ts-ignore
const getFlow = (state) => state.remoteConfig.flow;

export const selectFlowBlocks = createSelector([getFlow], (flow) => {
  let r = normalizeBlocks(flow);
  r = r.map(i => _intersection(i, ROUTES)).filter(i => !!i.length);
  return r;
});

export const selectFlow = createSelector([selectFlowBlocks], (flow) => {
  const flatFlow = flow.flat();
  return flatFlow;
});
