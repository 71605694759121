import { useLayoutEffect, useRef } from 'react'
import { useLocation } from 'react-router';

interface Props extends ScrollToOptions {
  mode?: 'ref' | 'window' | 'root'
}

export const useScrollOnLocationChange = ({ mode = 'ref', top = 0, behavior = 'smooth' }: Props = {}) => {
  const ref = useRef<HTMLDivElement>(null);

  const location = useLocation();

  useLayoutEffect(() => {
    setTimeout(() => {
      switch (mode) {
        case 'ref': {
          if (ref.current) {
            ref.current.scrollTo({ top, behavior })
          }

          break;
        }
        case 'root': {
          const rootEl = document.getElementById('root')

          if (rootEl) {
            rootEl.scrollTo({ top, behavior })
          }

          break;
        }

        case 'window':
        default: {
          window.scrollTo({ top, behavior })

          break;
        }
      }
    }, 200);
  }, [mode, top, behavior, location.pathname])

  return ref
}