import { createAsyncThunk } from '@reduxjs/toolkit';

import Analytics from '@web-solutions/module-analytics';
import Attribution from '@web-solutions/module-attribution';

import { appSlice, type AppState } from '.';

export const { setLoaded, setAppLink, setPending } = appSlice.actions;

export const initAppLink = createAsyncThunk(
  `${appSlice.name}/initAppLink`,
  async (_, { dispatch }) => {
    const appLink = await Attribution.getLinkToApp();
    dispatch(setAppLink(appLink));
  }
);

export const downloadApp = createAsyncThunk(
  `${appSlice.name}/downloadApp`,
  async (_, { getState }) => {
    const state = getState();
    const { app: { appLink } } = state as { app: AppState };
    Analytics.trackEvent('install_app', 'click');
    window.location.href = appLink;
  }
);