import React from 'react';
import classNames from 'classnames';

import classes from './style.module.scss';

const Section = React.forwardRef<HTMLElement, React.ComponentProps<'section'>>(({ children, className, ...rest }, ref) => (
  <section ref={ref} className={classNames(classes.wrap, className)} {...rest}>
    {children}
  </section>
));

export default Section;
