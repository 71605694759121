import React from 'react';

const FORM_CONTAINER_CLASSNAME = 'paddle-payment-form-container';

class CardElement extends React.Component {
  componentDidMount() {
    const { config, } = this.props;

    if (config?.sandbox) {
      window.Paddle.Environment?.set('sandbox');
    }

    window.Paddle.Setup({
      vendor: config?.vendor_id,
      eventCallback: (data) => {
        console.log(data);
        if (data.event === 'Checkout.Loaded') {
          if (this.props.onReady) {
            this.props.onReady({
              checkoutId: data?.eventData?.checkout?.id,
              productId: data?.eventData?.product?.id,
            });
          }
        } else if (data.event === 'Checkout.Login') {
          if (this.props.onUserInfoChange) {
            this.props.onUserInfoChange({
              email: data?.eventData?.user?.email,
            });
          }
        } else if (data.event === 'Checkout.PaymentMethodSelected') {
          if (this.props.onEvent) {
            this.props.onEvent('payment_method_selected', {
              method: data?.eventData?.payment_method?.toLowerCase() || undefined,
            });
          }
        } else if (data.event === 'Checkout.PaymentMethodChange') {
          if (this.props.onEvent) {
            this.props.onEvent('payment_method_change');
          }
        } else if (data.event === 'Checkout.PaymentComplete') {
          if (this.props.onSuccess) {
            this.props.onSuccess({
              checkoutId: data?.eventData?.checkout?.id,
              productId: data?.eventData?.product?.id,
              amount: data?.eventData?.checkout?.prices?.customer?.total,
              currency: data?.eventData?.checkout?.prices?.customer?.currency,
              email: data?.eventData?.user?.email,
              method:
                data?.eventData?.payment_method === 'CARD'
                  ? 'card'
                  : data?.eventData?.payment_method
                    ? data?.eventData?.payment_method.toLowerCase() + '-paddle'
                    : undefined,
            });
          }
        } else if (data.event === 'Checkout.Error') {
          if (['email', 'postcode', 'cardNumber', 'name', 'expiry', 'cvv'].indexOf(data?.eventData?.error_type) === -1) {
            if (data?.eventData?.errors?.[0]?.pointer !== '/data/postcode') {
              if (this.props.onError) {
                this.props.onError({
                  message: data?.eventData?.error,
                  method: data?.eventData?.error_method === 'form' ? 'card' : data?.eventData?.error_method,
                  code: data?.eventData?.code || data?.eventData?.error_code || data?.eventData?.error_type,
                });
              }
            }
          }
        }
      },
    });

    this._initForm();
  }

  componentDidUpdate(prevProps) {
    if (this.props.product?.id !== prevProps.product?.id || this.props.options?.config?.email !== prevProps.options?.config?.email) {
      this._initForm();
    }
  }

  _initForm = () => {
    const { product, options, } = this.props;

    const c = document.querySelector('.' + FORM_CONTAINER_CLASSNAME);
    if (c) {
      c.innerHTML = '';
    }

    window.Paddle.Checkout.open({
      method: 'inline',
      product: +product?.id,
      override: product?.pay_link,
      email: options?.config?.email,
      country: options?.config?.country,
      postcode: options?.config?.postcode,
      passthrough: options?.config?.passthrough,
      success: options?.config?.success,
      allowQuantity: false,
      disableLogout: true,
      frameTarget: FORM_CONTAINER_CLASSNAME,
      frameInitialHeight: 416,
      frameStyle:
        'width:100%; min-width:312px; background-color: transparent; border: none;',
    });
  };

  render() {
    return <div className={FORM_CONTAINER_CLASSNAME} />;
  }
}

export default CardElement;
