import request from '@web-solutions/core/utils/network';

import type { ColorTypeResponse } from './types'

export const fetchColorTypeByFaceImage = (img: File): Promise<ColorTypeResponse> => {
  const formData = new FormData();

  formData.append('file', img);

  return request.post('/ai/color-type-by-file', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}