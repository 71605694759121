/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  CardElement as CompactCardElement,
  CardNumberElement,
  CardMonthElement,
  CardYearElement,
  CardCvvElement,
} from '@recurly/react-recurly';

import classes from './styles.module.css';

const FIELDS = {
  COMPACT: 'compact',
  Numeric: 'number',
  MONTH: 'month',
  YEAR: 'year',
  CVV: 'cvv',
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
};

export const CardElement = React.forwardRef(
  ({ className, onSubmit, onChange, onReady, options }, ref) => {
    const isCompact = options && options.layout === 'compact';

    const refs = {
      [FIELDS.Numeric]: useRef(),
      [FIELDS.MONTH]: useRef(),
      [FIELDS.YEAR]: useRef(),
      [FIELDS.CVV]: useRef(),
      [FIELDS.FIRST_NAME]: useRef(),
      [FIELDS.LAST_NAME]: useRef(),
    };

    const [touched, setTouched] = useState(false);

    const [ready, setReady] = useState(
      isCompact
        ? {
          [FIELDS.COMPACT]: false,
        }
        : {
          [FIELDS.Numeric]: false,
          [FIELDS.MONTH]: false,
          [FIELDS.YEAR]: false,
          [FIELDS.CVV]: false,
        }
    );

    const [valid, setValid] = useState({
      [FIELDS.Numeric]: false,
      [FIELDS.MONTH]: false,
      [FIELDS.YEAR]: false,
      [FIELDS.CVV]: false,
      [FIELDS.FIRST_NAME]: false,
      [FIELDS.LAST_NAME]: false,
    });

    const handleChange = (field, e) => {
      if (field === FIELDS.FIRST_NAME || field === FIELDS.LAST_NAME) {
        e.valid = !!e.target.value;
      } else {
        if (!ready[field]) {
          setReady({
            ...ready,
            [field]: true,
          });
        }
      }

      if (e.empty === false && !touched) {
        setTouched(true);
      }

      setValid({
        ...valid,
        [field]: e.valid,
      });
    };

    useEffect(() => {
      if (touched) {
        const v = Object.values(valid).reduce(
          (prev, current) => !!prev && !!current,
          true
        );
        onChange({
          valid: v,
        });
      }
    }, [valid, touched]);

    useEffect(() => {
      const r = Object.values(ready).reduce(
        (prev, current) => !!prev && !!current,
        true
      );
      if (r && onReady) {
        onReady();
      }
    }, [onReady, ready]);

    return (
      <div className={className}>
        {isCompact ? (
          <CompactCardElement
            ref={ref}
            onSubmit={onSubmit}
            onChange={handleChange.bind(null, FIELDS.COMPACT)}
            style={{
              fontFamily: 'Open Sans',
            }}
          />
        ) : (
          <React.Fragment>
            <CardNumberElement
              ref={refs[FIELDS.Numeric]}
              onSubmit={onSubmit}
              onChange={handleChange.bind(null, FIELDS.Numeric)}
              className={classes['recurly-element-number']}
              style={{
                placeholder: { content: 'Card number' },
                fontFamily: 'Open Sans',
              }}
            />
            <div className={classes['recurly-elements-row']}>
              <CardMonthElement
                ref={refs[FIELDS.MONTH]}
                onSubmit={onSubmit}
                onChange={handleChange.bind(null, FIELDS.MONTH)}
                className={classes['recurly-element-month']}
                style={{
                  placeholder: { content: 'MM' },
                  fontFamily: 'Open Sans',
                }}
              />
              <CardYearElement
                ref={refs[FIELDS.YEAR]}
                onSubmit={onSubmit}
                onChange={handleChange.bind(null, FIELDS.YEAR)}
                className={classes['recurly-element-year']}
                style={{
                  placeholder: { content: 'YY' },
                  fontFamily: 'Open Sans',
                }}
              />
              <CardCvvElement
                ref={refs[FIELDS.CVV]}
                onSubmit={onSubmit}
                onChange={handleChange.bind(null, FIELDS.CVV)}
                className={classes['recurly-element-cvv']}
                style={{
                  placeholder: { content: 'CVV' },
                  fontFamily: 'Open Sans',
                }}
              />
            </div>
          </React.Fragment>
        )}
        <div className={classes['recurly-elements-row']}>
          <input
            ref={refs[FIELDS.FIRST_NAME]}
            onChange={handleChange.bind(null, FIELDS.FIRST_NAME)}
            data-recurly='first_name'
            name='first_name'
            placeholder='First Name'
            className={classNames(
              'recurly-element',
              classes['recurly-element-first_name']
            )}
          />
          <input
            ref={refs[FIELDS.LAST_NAME]}
            onChange={handleChange.bind(null, FIELDS.LAST_NAME)}
            data-recurly='last_name'
            name='last_name'
            placeholder='Last Name'
            className={classNames(
              'recurly-element',
              classes['recurly-element-last_name']
            )}
          />
        </div>
      </div>
    );
  }
);

CardElement.propTypes = {
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  onReady: PropTypes.func,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  className: PropTypes.string,
};

CardElement.defaultProps = {
  onSubmit: null,
  onChange: null,
  onReady: null,
  onSuccess: null,
  onError: null,
  className: null,
  options: null,
};
